import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import { FIREBASE_CONFIG } from '../properties';

class Firebase {
  constructor() {
    app.initializeApp(FIREBASE_CONFIG);
    /* Firebase APIs */

    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();
  }

  login = (token) => this.auth.signInWithCustomToken(token);

  users = () => this.db.collection('users');

  firmaDigitalVidaMas = () => this.db.collection('firma_digital_vida_mas');

  createEstadisticasFDVM = (lista) => {
    return new Promise(async (resolve, reject) => {
      const db = this.db;
      const totalDocs = [];
      for (const key in lista) {
        const snap = await this.db
          .collection('firma_digital_vida_mas')
          .doc('solicitudes_generadas')
          .collection(lista[key])
          .get();
        console.log('snap', snap.docs);

        const docs = snap.docs;
        for (const doc of docs) {
          totalDocs.push(doc.id);
          db.collection('modulo_vidamas')
            .doc(doc.data().no_solicitud)
            .set(doc.data(), { merge: true });
        }
        if (Number(key) === lista.length - 1) {
          resolve({
            numColecciones: lista.length,
            numDocs: totalDocs.length,
            estatus: 'Terminado',
          });
        }
      }
    });
  };

  deleteEstadisticasFDVM = () => {
    return new Promise((resolve, reject) => {
      const db = this.db;
      this.db
        .collection('modulo_vidamas')
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            db.collection('modulo_vidamas').doc(doc.id).delete();
          });
          resolve({ estatus: 'Terminado' });
        })
        .catch(function (error) {
          reject({ estatus: 'Error' });
          console.log('Error al eliminar documentos estadisticas: ', error);
        });
    });
  };
  firmaDigitalVidaMasFiles = () =>
    this.storage.ref().child('firma_digital_vida_mas');

  getData = (idPart, noSolicitud) =>
    this.firmaDigitalVidaMas()
      .doc('solicitudes_generadas')
      .collection(idPart)
      .doc(noSolicitud)
      .get();

  updateData = (idPart, dataSet, noSolicitud) =>
    this.firmaDigitalVidaMas()
      .doc('solicitudes_generadas')
      .collection(idPart)
      .doc(noSolicitud)
      .update({ firma_data: dataSet });

  searchData = () => this.firmaDigitalVidaMas().doc('solicitudes_generadas');
}

export default new Firebase();
