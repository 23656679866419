import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DropDown from "../components/Generico/DropDown";
import SnackBarAlert from "../components/Generico/SnackBarAlert";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import PersonAddIcon from "@material-ui/icons/PersonAddOutlined";
import TextField from "@material-ui/core/TextField";
import NumberFormat from "react-number-format";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import { 
  checkisEmpty, 
  getErrorTextImporte, 
  structBeneficiario,
  reOrderListBeneficiarios,
  changeValueBene,
  validateBeneficiarios,
  validateFormVida
} from "../Utils/Tools";
import {updateDataSolicitud} from "../Utils/FirebaseMethods";
import { SolicitudFdd } from "../components/Generico/SolicitudFDD";
import Firebase from "../Utils/Firebase";
import BeneficiarioItem from "../components/Generico/BeneficiarioItem";
import FilesTable from "../components/Generico/FilesTable";

import { showLoading } from "../hooks/showLoading";
import { FORMULARIO_PRINCIPAL } from '../Utils/MensajesSnack';

const useStyles = makeStyles((theme) => ({
  container: {
    background: "#EEEEEE",
    flexDirection: "column",
    paddingBottom: "20px",
    paddingTop: "30px",
  },
  cardContainer: {
    marginLeft: "2%",
    marginRight: "2%",
    flexdirection: "column",
  },
  areaDocumentos: {
    padding: "2%",
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
  },
  areaBeneficiarios: {
    padding: "2%",
    flexDirection: "column",
    display: "flex",
  },
  titleBeneficiarios: {
    flexDirection: "row",
    alignContent: "center",
    alignItems: "flex-end",
    display: "flex",
    justifyContent: "space-between",
  },
  btnContinuarFirmantes: {
    padding: "2%",
  },
}));

function useAsyncState(initialValue) {
  const [value, setValue] = useState(initialValue);
  const setter = (x) =>
    new Promise((resolve) => {
      setValue(x);
      resolve(x);
    });
  return [value, setter];
}

const stateSnack = {
  msjSnackbar: '',
  severitySnackBar: 'success',
  openSnackBar: false,
};

export default function FormularioVida({
  idPart,
  solicitud,
  ramoSelected,
  documentosCat,
  productosCat,
  parentescosCat,
  props,
}) {
  const classes = useStyles();
  const [state, setState] = useState({
    lastChange: null,
    documents: {},
    pictures: {},
    beneficiarios: [],
    porcentajeTotal: 0,
    documento: "",
    producto: "",
    importe: "",
    continueFirmantes: false,
    noSolicitud: solicitud,
  });
  const [snackbar, setSnack] = useAsyncState(stateSnack);
  const { openSnackBar, msjSnackbar, severitySnackBar } = snackbar;
  const { continueFirmantes, documents, pictures, noSolicitud, documento, producto, 
    beneficiarios, porcentajeTotal, importe, lastChange } = state;

  const fetchFirebase = useCallback(async () => {
    try {
      showLoading(true);
      const result = await Firebase.getData(idPart, noSolicitud);
      const { solicitud_data: { producto, importe, documento, beneficiarios },
        archivos: { documents, pictures }} = result.data();
      showLoading(false);
      setState({...state, producto, importe, documento, documents,
        pictures, beneficiarios, data: result.data()});
    } catch (error) {
      showLoading(false);
    }
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (documento || producto || importe || lastChange) {
      setDataToFirestore();
    } else {
      fetchFirebase();
    }
  }, [documento, producto, importe, lastChange]);// eslint-disable-line react-hooks/exhaustive-deps

  function addBeneficiario() {
    var date = new Date();
    const lista = beneficiarios;
    if (lista.length < 6) {
      lista.push(structBeneficiario(lista.length));
      setState({...state, beneficiarios: lista, lastChange: date});
    } else {
      setSnack(FORMULARIO_PRINCIPAL.warning_beneficiarios);
    }
  }

  function deleteBeneficiario(index) {
    var date = new Date();
    const lista = beneficiarios;
    lista.splice(index, 1);
    const reorderList = reOrderListBeneficiarios(lista);
    setState({...state, beneficiarios: reorderList, lastChange: date});
  }

  function closeSnack() {
    setSnack(FORMULARIO_PRINCIPAL.init_snack)
  }

  function onChangeDocument(id, value) {
    setState({...state,documento: value});
  }

  function onChangeProducto(id, value) {
    setState({...state,producto: value});
  }

  function onChangeBeneficiario(id, value) {
    var tempBeneficiarios = changeValueBene(beneficiarios, id, value);
    setState({...state,beneficiarios: tempBeneficiarios});
    setDataToFirestore();
  }

  function onChangeImporte(e) {
    setState({...state,importe: e.target.value});
  }

  function setDataToFirestore() {
    var data = {documento: documento,producto: producto,beneficiarios: beneficiarios,importe: importe};
    updateDataSolicitud(idPart, data, noSolicitud);
    validateForm();
  }

  function validateForm() {
    var beneficiariosValidate = validateBeneficiarios(beneficiarios);
    if (validateFormVida(ramoSelected, documento, producto, importe, beneficiariosValidate, documents)) {
    setState({...state,porcentajeTotal: beneficiariosValidate[1],continueFirmantes: true});
    } else {
    setState({...state,porcentajeTotal:beneficiariosValidate[1] ,continueFirmantes: false,});
    }
  }

  return (
    <div>
      <div>
        <Grid container spacing={4} className={classes.areaDocumentos}>
          <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
            <DropDown
              fullWidth
              label="Tipo de documento"
              items={documentosCat.filter(documento => documento.data().ramo === ramoSelected)}
              onChange={onChangeDocument}
              valueDrop={documento}/>
          </div>
          <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
            <DropDown
              fullWidth
              label="Producto"
              items={productosCat.filter(documento => documento.data().ramo === ramoSelected)}
              onChange={onChangeProducto}
              valueDrop={producto}/>
          </div>
        </Grid>
        <Divider />
        <div className={classes.areaBeneficiarios}>
          <div className={classes.titleBeneficiarios}>
            <div style={{flexDirection: "row",display: "flex",alignContent: "center",alignItems: "flex-end",}}>
             <Typography variant="h6">Beneficiarios</Typography>
            </div>
              <Button
                variant="contained"
                color="primary"
                onClick={addBeneficiario}
                size="medium"
                startIcon={<PersonAddIcon/>}>
                Agregar beneficiario
              </Button>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row"}}>
            {beneficiarios.map((beneficiario, index) => {
              return (
                <BeneficiarioItem
                  key={index}
                  index={index}
                  quit={deleteBeneficiario}
                  onChangeData={onChangeBeneficiario}
                  beneficiarioData={beneficiario}
                  parentescos={parentescosCat}
                  porcentajeTotal={porcentajeTotal}
                />);})}
          </div>
          <div style={{ marginTop: "10px", paddingLeft: "1%" }}>
              <TextField
                required
                error={checkisEmpty(importe) || parseFloat(importe) < 1}
                helperText={getErrorTextImporte(checkisEmpty(importe), importe)}
                id="input-importe"
                name="importe"
                value={importe}
                label="Importe"
                InputProps={{endAdornment: (<InputAdornment position="end">MXN</InputAdornment>),
                  inputComponent: NumberFormatCustom}}
                size="small"
                onChange={onChangeImporte}
              />
          </div>
        </div>
        <Divider />
        <div className={classes.areaBeneficiarios}>
          <FilesTable
          idPart ={idPart} noSolicitud ={noSolicitud}
          documents={documents} pictures={pictures}
          state={state} setState={setState}
          setSnack={setSnack}/>
        </div>
        <Divider></Divider>
          <div className={classes.btnContinuarFirmantes}>
            <SolicitudFdd
              visible = {continueFirmantes}
              title={"¡Los datos se encuentran completos!"}
              handleCancel={(e)=> props.history.push({pathname: "/"})}
              handleComplete={(e) =>  props.history.push({pathname: "/firmantes-solicitud",state: { noSolicitud: noSolicitud }})}
            />
          </div>
      </div>
      <SnackBarAlert isOpen={openSnackBar} message={msjSnackbar}
        closeSnack={closeSnack} severity={severitySnackBar}/>
    </div>);
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
}
