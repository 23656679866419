import { PDFDocument } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import {
  X30,
  X40,
  X170,
  X300,
  addHeaders,
  mergePdfs,
  embedImages,
} from './Coordenadas';
export async function createPdfGmm(solicitud) {
  const {
    solicitud_data: { documento },
    firma_data: {
      cys,
      firmantes: { asesor, contratante, solicitante },
    },
    archivos: { documents, pictures },
  } = solicitud;

  const pdfDoc = await PDFDocument.create();
  pdfDoc.registerFontkit(fontkit);
  await mergePdfs(pdfDoc, documents);
  await embedImages(pdfDoc, pictures);

  let page = pdfDoc.addPage();
  let pageAgente = pdfDoc.addPage();
  const { height } = page.getSize();

  page = addHeaders(page, height, true);
  page.drawText(
    `Ratifico que la información personal o de terceros contenida en el formato de ${documento}
que se incorpora a la presente carta es cierta y está completa,dicha información fue proporcionada
por mí al Agente de Seguros, quien me explicó los derechos y obligaciones que entre el solicitante y
GNP Seguros surjan derivados de las instrucciones contenidas en la solicitud referida, así como
el contenido de las leyendas legales que forman parte de la misma, por lo tanto, confirmo que estoy
de acuerdo en contratar  mi póliza de seguro.`,
    X30(height)
  );
  page.drawText(
    `
  ______________________________________
            Nombre y Firma del Solicitante
    ${cys ? contratante.nombre : solicitante.nombre}
    Correo: ${cys ? contratante.correo : solicitante.correo}
    Teléfono: ${cys ? contratante.telefono : solicitante.telefono}
  `,
    X40(height)
  );
  page.drawText(
    `
  ______________________________________
            Nombre y Firma del Contratante
    ${contratante.nombre}
    Correo: ${contratante.correo}
    Teléfono: ${contratante.telefono}
  `,
    X300(height)
  );
  /**
   * Agente
   */
  pageAgente = addHeaders(pageAgente, height);
  pageAgente.drawText(
    `${asesor.nombre} Agente de Seguros de GNP, declaro que  realicé una entrevista con el Contratante o
Representante Legal que firma el formato ${documento}, con la finalidad de obtener los datos
personales que fueron asentados en el formato. Así mismo,  validé los documentos de identificación
exhibidos por éste.`,
    X30(height)
  );

  pageAgente.drawText(
    `
  __________________________________________
                    Nombre y Firma del Agente de Seguros
    ${asesor.nombre}
    Correo: ${asesor.correo}
    Teléfono: ${asesor.telefono}
  `,
    X170(height)
  );

  const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
  return {
    pdfDataUri,
    numPag: pdfDoc.getPageCount(),
  };
}
