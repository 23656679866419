import React, { useState, useEffect, useCallback, useContext } from "react";
import { makeStyles} from "@material-ui/core/styles";
import RadioGroupRamo from "../components/Generico/RadioGroupRamo";
import Card from "@material-ui/core/Card";
import HomeButton from "../components/Generico/HomeButton";
import {
  updateDataSolicitud,
  getRamos,
  getProductos,
  getParentescos,
  getTiposDocumentos,
} from "../Utils/FirebaseMethods";
import Firebase from "../Utils/Firebase";
import { CardFolio } from "../components/Generico";

import { showLoading } from "../hooks/showLoading";
import FormularioVida from "../Forms/FormularioVida";
import FormularioGMM from "../Forms/FormularioGMM";
import { Context } from "../Context";

const useStyles = makeStyles((theme) => ({
  container: {
    background: "#EEEEEE",
    flexDirection: "column",
    paddingBottom: "20px",
    paddingTop: "30px",
  },
  cardContainer: {
    marginLeft: "2%",
    marginRight: "2%",
    flexdirection: "column",
  },
  areaRamo: {
    padding: "2%",
    flexDirection: "column",
    display: "flex",
    textAlign: "center",
  },
  areaHomeButton:{
    padding: "2%",
    display: "flex",
  }
}));


export const FormularioSolicitud = (props) => {
  const { sesionData } = useContext(Context);
  const classes = useStyles();
  const [state, setState] = useState({
    ramos: [],
    documentos: [],
    productos: [],
    parentescos: [],
    ramo: "",
    oldRamo:null,
    noSolicitud: props.location.state.solicitud,
  });

  const {
    ramos,
    oldRamo,
    documentos,
    productos,
    parentescos,
    noSolicitud,
    ramo,
  } = state;

  const fetchFirebase = useCallback(async () => {
    try {
      showLoading(true);
      const result = await Firebase.getData(sesionData.idPart, noSolicitud);
      const ramos = await getRamos();
      const productos = await getProductos();
      const parentescos = await getParentescos();
      const documentos = await getTiposDocumentos();
      const {
        solicitud_data: {ramo},
      } = result.data();
      showLoading(false);
      setState({
        ...state,
        ramos: ramos.docs,
        documentos: documentos.docs,
        productos: productos.docs,
        parentescos: parentescos.docs,
        ramo,
        data: result.data(),
      });
    } catch (error) {
      showLoading(false);
    }
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (ramo) {
      setDataToFirestore();
    } else {
      fetchFirebase();
    }
  }, [ramo]); // eslint-disable-line react-hooks/exhaustive-deps

  function onChangeRamo(value) {
    setState({
      ...state,
      oldRamo: ramo,
      ramo: value,
    });
  }

  function setDataToFirestore() {
    var data;
    if(oldRamo === "" || oldRamo === null){
      data = {
        ramo: ramo,
      };
    }else{
      data = {
        ramo: ramo,
        documento: null,
        producto:null
      };
    }
    updateDataSolicitud(sesionData.idPart, data, noSolicitud);
  }

  function showForm(valueRamo){
    switch (valueRamo) {
      case "Vida":
        return <FormularioVida 
          idPart={sesionData.idPart} 
          solicitud={noSolicitud} 
          ramoSelected={ramo} 
          documentosCat={documentos} 
          productosCat={productos} 
          parentescosCat={parentescos} 
          props={props}/>;
      case "GMM":
        return <FormularioGMM 
          idPart={sesionData.idPart} 
          solicitud={noSolicitud} 
          ramoSelected={ramo} 
          documentosCat={documentos} 
          productosCat={productos} 
          props={props}/>;   
      default:
        return <div></div>
    }
  }

  return (
    <div className={`${classes.container} container-pages`}>
      <Card className={classes.cardContainer}>
        <div className={classes.areaHomeButton}>
          <HomeButton props={props} goHome={true}/>
        </div> 
        <CardFolio solicitud={noSolicitud} />
        <div className={classes.areaRamo}>
          <RadioGroupRamo
            items={ramos}
            valueRamo={ramo}
            onChange={onChangeRamo}
          />
        </div>
        {showForm(ramo)}
      </Card>
    </div>
  );
};
