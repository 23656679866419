import axios from 'axios';
import { PDFDocument } from 'pdf-lib';
export const LEYENDAS = {
  header_left: `
Grupo Nacional Provincial, S.A.B
Av. Cerro de las Torres, número 395
Colonia Campestre Churubusco
Alcaldía Coyoacán
C.P. 04200`,
};

export const months = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

export const formatDate = function getFormatDate(date) {
  const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
  const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
  const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
  return { da, mo, ye };
};

export const addHeaders = (pages, height, leyendas = false) => {
  const fch = formatDate(new Date());
  const month = new Date().getMonth();
  if (leyendas) {
    pages.drawText(
      `El texto sólo sustituye la firma del cliente no el resto de la documentación`,
      X32(height)
    );
  }

  pages.drawText(
    `Ciudad de México a ${fch.da} de ${months[month]} ${fch.ye}`,
    X350(height)
  );
  pages.drawText(LEYENDAS.header_left, X33(height));

  pages.drawText(`Representante`, X34(height));

  return pages;
};

export const mergePdfs = async (mergedPdf, documents) => {
  try {
    const pdfsToMerge = await getBytes(documents);

    for (const pdfCopyDoc of pdfsToMerge) {
      const pdf = await PDFDocument.load(pdfCopyDoc.bytes, {
        ignoreEncryption: true,
      });
      const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
      copiedPages.forEach((page) => {
        mergedPdf.addPage(page);
      });
    }

    return mergedPdf;
  } catch (error) {
    console.log('Ocurrio un error al procesar los documentos.', error);
    return error;
  }
};

async function getBytes(docImg) {
  const arrayPDFs = [];
  try {
    if (Object.keys(docImg).length > 0) {
      for (const key in docImg) {
        const pdfsToMerge = await axios.get(docImg[key].url, {
          responseType: 'arraybuffer',
        });

        arrayPDFs.push({
          bytes: pdfsToMerge.data,
          name: docImg[key].name,
        });
      }
    }
  } catch (error) {
    console.log('Error al obtener bytes', error);
  }
  return arrayPDFs;
}

export const embedImages = async (pdfDoc, pictures) => {
  try {
    const imgList = await getBytes(pictures);
    for (const img of imgList) {
      let imgEmbed;
      const getExt = img.name.split('.');
      const ext = getExt[getExt.length - 1];

      if (ext === 'jpg' || ext === 'jpeg') {
        imgEmbed = await pdfDoc.embedJpg(img.bytes);
      } else {
        imgEmbed = await pdfDoc.embedPng(img.bytes);
      }
      const reduce = imgEmbed.scale(0.5);
      const page = pdfDoc.addPage();

      page.drawImage(imgEmbed, {
        x: page.getWidth() / 2 - reduce.width / 2,
        y: page.getHeight() / 2 - reduce.height / 2,
        width: reduce.width,
        height: reduce.height,
      });
    }
  } catch (error) {
    console.log('Ocurrio un error en el proceso de las imagenes', error);
  }
  return pdfDoc;
};

export const X30 = function getX30(height) {
  return {
    x: 30,
    y: height - 17 * 12,
    size: 12,
    maxWidth: 540,
    lineHeight: 15,
  };
};
export const X32 = function getX32(height) {
  return {
    x: 30,
    y: height - 5 * 7,
    size: 12,
  };
};

export const X33 = function getX33(height) {
  return {
    x: 30,
    y: height - 7 * 12,
    size: 12,
    maxWidth: 210,
    lineHeight: 15,
  };
};
export const X34 = function getX34(height) {
  return {
    x: 30,
    y: height - 15 * 12,
    size: 12,
  };
};
export const X40 = function getX40(height) {
  return {
    x: 40,
    y: height - 60 * 12,
    size: 10,
    lineHeight: 15,
  };
};
export const X170 = function getX170(height) {
  return {
    x: 170,
    y: height - 60 * 12,
    size: 10,
    lineHeight: 15,
  };
};

export const X300 = function getX300(height) {
  return {
    x: 300,
    y: height - 60 * 12,
    size: 10,
    lineHeight: 15,
  };
};

export const X350 = function getX350(height) {
  return {
    x: 350,
    y: height - 5 * 12,
    size: 12,
  };
};
