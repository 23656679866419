import { useState } from 'react';

export const useInputValue = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState(true);

  const onChange = (e) => {
    const type = e.target.name;
    const value = e.target.value;
    validateChars(type, value);
    setValue(value);
  };

  const validateChars = (type, value) => {
    let validation = /^([A-Za-zÁÉÍÓÚñáéíóúÑ]+[\s])+([A-Za-zÁÉÍÓÚñáéíóúÑ]{0}?[A-Za-zÁÉÍÓÚñáéíóúÑ'])+[\s]?([A-Za-zÁÉÍÓÚñáéíóúÑ])?$/g;
    switch (type) {
      case 'correo':
        validation = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        break;
      case 'telefono':
        validation = /^[1-9]{2}[0-9]{8}$/;
        break;
      default:
        break;
    }
    setError(validation.test(value));
  };

  return { value, error, onChange };
};
