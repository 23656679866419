import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import TextField from '@material-ui/core/TextField';

import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { URL_BASE_FAD } from "../../properties";

import Chip from '@material-ui/core/Chip';
import {green, yellow } from '@material-ui/core/colors';


const stylesPreviewFirmantes = (theme) => ({
    rootDialog: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButtonDialog: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitleFirmantes = withStyles(stylesPreviewFirmantes)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.rootDialog} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButtonDialog}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContentFirmantes = withStyles((theme) => ({
    root: {
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
    },
}))(MuiDialogContent);

export default function PreviewFirmantes({ state, onClose}) {

    const [viewURL, setView] = React.useState({
        view: false,
        url:""
    });

    function enviaWhats(firmante, e) {
        e.preventDefault();
        const numero = getTelefonoFirmante(firmante.signerName);
        const urlFAD = URL_BASE_FAD + getTicketFirmante(firmante.order);
        const url = "https://api.whatsapp.com/send?phone=52" + numero + "&text=Hola%20este%20es%20tu%20enlace%20para%20completar%20tu%20proceso%20de%20firma.%20" + urlFAD;
        const win = window.open(url, '_blank');
        win.focus();
    }

    function viewUrlFirmante(firmante, e) {
        const urlFAD = URL_BASE_FAD + getTicketFirmante(firmante.order);
        setView({
            ...viewURL,
            view: true,
            url: urlFAD,
        })
      }

    function getTicketFirmante(order) {
        var ticketFirmante = "";
        Object.keys(state.dataSolicitudFirmantes.firma_data.allTickets).forEach(ticket => {
            if (ticket === order.toString()) {
                ticketFirmante = state.dataSolicitudFirmantes.firma_data.allTickets[ticket];
            }
        });
        return ticketFirmante;
    }

    function getCorreoFirmante(nombreFirmante) {
        var correo = "";
        Object.keys(state.dataSolicitudFirmantes.firma_data.firmantes).forEach(firmante => {
            if (state.dataSolicitudFirmantes.firma_data.firmantes[firmante].nombre.includes(nombreFirmante)) {
                correo = state.dataSolicitudFirmantes.firma_data.firmantes[firmante].correo;
            }
        });
        return correo;
    }

    function getTelefonoFirmante(nombreFirmante) {
        var telefono = "";
        Object.keys(state.dataSolicitudFirmantes.firma_data.firmantes).forEach(firmante => {
            if (state.dataSolicitudFirmantes.firma_data.firmantes[firmante].nombre.includes(nombreFirmante)) {
                telefono = state.dataSolicitudFirmantes.firma_data.firmantes[firmante].telefono;
            }
        });
        return telefono;
    }

    const handleClose = () => {
        onClose();
        setView({
            ...viewURL,
            view:false,
            url:""
        });
    };

    return (
            <Dialog
                maxWidth="lg"
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={state.openPreviewFirmantes}
            >
                <DialogTitleFirmantes id="customized-dialog-title" onClose={handleClose}>
                    {state.titlePreviewFirmantes}
                </DialogTitleFirmantes>
                <DialogContentFirmantes dividers>
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Orden</TableCell>
                                    <Hidden smDown>
                                        <TableCell align="left">Nombre</TableCell>
                                    </Hidden>
                                    <TableCell align="left">Correo</TableCell>
                                    <Hidden smDown>
                                        <TableCell align="left">Teléfono</TableCell>
                                    </Hidden>
                                    <TableCell align="left">Estado de firma</TableCell>
                                     <Hidden smDown>
                                        <TableCell align="left"> Compartir url</TableCell>
                                    </Hidden>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {state.dataPreviewFirmantes.map((firmante) => (
                                    <TableRow key={firmante.signerName}>
                                        <TableCell align="center">{firmante.order}</TableCell>
                                        <Hidden smDown><TableCell align="left">{firmante.signerName}</TableCell></Hidden>
                                        <TableCell align="left">{getCorreoFirmante(firmante.signerName)}</TableCell>
                                        <Hidden smDown> <TableCell align="left">{getTelefonoFirmante(firmante.signerName)}</TableCell></Hidden>
                                        <TableCell align="left">{firmante.signed ?
                                            <Chip label=" Firma realizada" style={{color:"#FFFFFF", background: green[700]}}/>:
                                            <Chip label=" Firma pendiente" style={{color:"#FFFFFF", background: yellow[700]}}/>}
                                        </TableCell> 
                                        <Hidden smDown>
                                            <TableCell align="center">
                                                <Tooltip title="Compartir URL de firma por WhatsApp">
                                                    <IconButton onClick={(e) => enviaWhats(firmante, e)}>
                                                        <WhatsAppIcon style={{ color: "#43A047" }} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title='Ver URL de firma'>
                                                    <IconButton onClick={(e) => viewUrlFirmante(firmante, e)}>
                                                        <VisibilityIcon style={{ color: "#43A047" }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </Hidden>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div style={{paddingTop:"2%"}}>
                     {viewURL.view ?
                     <TextField multiline disabled fullWidth id="url-text" variant="outlined" value={viewURL.url} style={{background:"#e3f2fd"}}/>:
                     <div/>
                     }
                    </div>
                </DialogContentFirmantes>
            </Dialog>
    );
}
