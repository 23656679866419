import React, { useState, useEffect } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

export default function RadioGroupRamo({ items, onChange, valueRamo }) {
  const [value, setValue] = useState(valueRamo);
  //eslint-disable-next-line
  useEffect(() => {
    setValue(valueRamo);
  });

  const handleChange = (event) => {
    setValue(event.target.value);
    onChange(event.target.value);
  };
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        Seleccione la información del documento:
      </FormLabel>
      <RadioGroup
        row
        style={{ justifyContent: "center" }}
        aria-label="position"
        name="position"
        onChange={handleChange}
        value={value}
      >
        {items.map((ramo, k) => (
          ramo.data().active ?
          <FormControlLabel
            style={{ marginRight: "4%" }}
            value={ramo.data().name}
            control={<Radio color="primary" />}
            label={ramo.data().name}
            key={k}
          /> :
          <FormControlLabel
            disabled
            style={{ marginRight: "4%" }}
            value={ramo.data().name}
            control={<Radio color="primary" />}
            label={ramo.data().name}
            key={k}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
