import React, { createContext, useState } from "react";
const jwt = require("jsonwebtoken");

export const Context = createContext();
const Provider = ({ children }) => {
  let user = {
    claims: {
      name: "",
      idPart: "",
      roles: "",
    },
  };
  if (window.sessionStorage.getItem("user") !== null) {
    user = JSON.parse(window.sessionStorage.getItem("user"));
  }

  const [sesionData, setSesion] = useState({
    name: user.claims.name,
    idPart: user.claims.idPart,
    roles: user.claims.roles,
  });
  const [isAuth, setIsAuth] = useState(() => {
    return window.sessionStorage.getItem("token");
  });

  const [uid] = useState(() => {
    return window.sessionStorage.getItem("uid");
  });

  const [LTPA] = useState(() => {
    try {
      return document.cookie.includes("LtpaToken2") ? document.cookie : null;
    } catch (error) {
      console.log("Error en LtpaToken", error);
      return false;
    }
  });

  const value = {
    isAuth,
    sesionData,
    uid,
    LTPA,
    activateAuth: async () => {
      const data = window.sessionStorage.getItem("auth");
      const auth = JSON.parse(data);
      if (auth !== null && auth.user && auth.authData) {
        const data = jwt.decode(auth.authData.jwt);
        const {
          claims: { givenname, idparticipante, roles, tipousuario },
        } = data;
        setSesion({ name: givenname, idPart: idparticipante, roles: roles });
        window.sessionStorage.setItem("user", JSON.stringify(data));
        window.sessionStorage.setItem("token", auth.authData.jwt);
        window.sessionStorage.setItem("uid", idparticipante);
        window.sessionStorage.setItem("tipoUsuario", tipousuario);
        window.sessionStorage.setItem("name", givenname);
        setIsAuth(auth.authData.jwt);
      }
    },
    closeSesion: async () => {
      sessionStorage.clear();
      window.localStorage.clear();
      setIsAuth(false);
    },
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

const Contexto = {
  Provider,
  Consumer: Context.Consumer,
};

export default Contexto;
