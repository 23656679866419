import Firebase from "./Firebase";

export const getData = () => {
  Firebase.users()
    .get()
    .then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        console.log("LOG 1", doc.data());
      });
    });
};

export const newSolicitud = (idPart) => {
  var increment = "0001";
  var dateFormat = getFormatDate(new Date());
  var noSolicitud = "";
  return new Promise((promesa) => {
    Firebase.firmaDigitalVidaMas()
      .doc("solicitudes_generadas")
      .collection(idPart)
      .orderBy("date")
      .get()
      .then((snapshot) => {
        if (snapshot.docs.length > 0) {
          var dataLastSolicitud = snapshot.docs[
            snapshot.docs.length - 1
          ].data();
          var dateLastSolicitud = getFormatDate(
            new Date(dataLastSolicitud.date.seconds * 1000)
          );
          if (dateFormat === dateLastSolicitud) {
            var id = parseInt(dataLastSolicitud.consecutivo);
            increment = (id + 1).toString();
            if (increment.length < 4) {
              for (var i = increment.length; i < 4; i++) {
                increment = "0" + increment;
              }
            }
          }
        }
        noSolicitud = "SM" + dateFormat + increment;
        var dataToSave = {
          agente_data: {
            uid: idPart,
            nombre: sessionStorage.getItem("name"),
          },
          solicitud_data: {
            ramo: null,
            documento: null,
            producto: null,
            beneficiarios: [],
            importe: null,
          },
          consecutivo: increment,
          date: new Date(),
          estatus_solicitud: "Pendiente",
          firma_data: {},
          archivos: { pictures: {}, documents: {}, FAD: {} },
          no_solicitud: noSolicitud,
        };
        Firebase.firmaDigitalVidaMas()
          .doc("solicitudes_generadas")
          .collection(idPart)
          .doc(noSolicitud)
          .set(dataToSave, { merge: true });
        promesa(noSolicitud);
      });
  });
};

export const updateDataSolicitud = (idPart, dataToUpdate, noSolicitud) => {
  Firebase.firmaDigitalVidaMas()
    .doc("solicitudes_generadas")
    .collection(idPart)
    .doc(noSolicitud)
    .set({ solicitud_data: dataToUpdate }, { merge: true });
};

export const deleteSolicitud = (idPart, noSolicitud) => {
  Firebase.firmaDigitalVidaMas()
    .doc("solicitudes_generadas")
    .collection(idPart)
    .doc(noSolicitud)
    .update({ estatus_solicitud: "Eliminada" });
};

export const updateEstatusSolicitud = (idPart, noSolicitud, estatus) => {
  Firebase.firmaDigitalVidaMas()
    .doc("solicitudes_generadas")
    .collection(idPart)
    .doc(noSolicitud)
    .update({ estatus_solicitud: estatus });
};

export const uploadFile = (idPart, file, noSolicitud) => {
  return new Promise((promesa) => {
    if (file.type && file.type.includes("pdf")) {
      var taskPdf = Firebase.firmaDigitalVidaMasFiles()
        .child(idPart)
        .child(noSolicitud)
        .child("Documents")
        .child(file.name);
        taskPdf.put(file).then((snapshot) => {
          taskPdf.getDownloadURL().then((url) => {
          Firebase.firmaDigitalVidaMas()
            .doc("solicitudes_generadas")
            .collection(idPart)
            .doc(noSolicitud)
            .get()
            .then((snap) => {
              var data = snap.data()["archivos"];
              data.documents[file.name] = {
                name: file.name,
                url: url,
                size: file.size,
              };
              Firebase.firmaDigitalVidaMas()
                .doc("solicitudes_generadas")
                .collection(idPart)
                .doc(noSolicitud)
                .update({ archivos: data });
              var returnData = { type: "doc", data: data.documents };
              promesa(returnData);
            });
        });
      });
    } else if (file.type && file.type.includes("image")) {
      var taskImage = Firebase.firmaDigitalVidaMasFiles()
        .child(idPart)
        .child(noSolicitud)
        .child("Pictures")
        .child(file.name);
        taskImage.put(file).then((snapshot) => {
          taskImage.getDownloadURL().then((url) => {
          Firebase.firmaDigitalVidaMas()
            .doc("solicitudes_generadas")
            .collection(idPart)
            .doc(noSolicitud)
            .get()
            .then((snap) => {
              var data = snap.data()["archivos"];
              data.pictures[file.name] = {
                name: file.name,
                url: url,
                size: file.size,
              };
              Firebase.firmaDigitalVidaMas()
                .doc("solicitudes_generadas")
                .collection(idPart)
                .doc(noSolicitud)
                .update({ archivos: data });
              var returnData = { type: "img", data: data.pictures };
              promesa(returnData);
            });
        });
      });
    } else {
      var task = Firebase.firmaDigitalVidaMasFiles()
        .child(idPart)
        .child(noSolicitud)
        .child("Documents")
        .child(`${noSolicitud}.pdf`);
      task.put(file, { contentType: "application/pdf" }).then((snapshot) => {
        task.getDownloadURL().then((url) => {
          Firebase.firmaDigitalVidaMas()
            .doc("solicitudes_generadas")
            .collection(idPart)
            .doc(noSolicitud)
            .get()
            .then((snap) => {
              var returnData = { type: "doc", url };
              promesa(returnData);
            });
        });
      });
    }
  });
};

export const deleteFile = (idPart, file, type, noSolicitud) => {
  return new Promise((promesa) => {
    if (type === "doc") {
      var taskDoc = Firebase.firmaDigitalVidaMasFiles()
        .child(idPart)
        .child(noSolicitud)
        .child("Documents")
        .child(file.name);
        taskDoc.delete().then((snapshot) => {
        Firebase.firmaDigitalVidaMas()
          .doc("solicitudes_generadas")
          .collection(idPart)
          .doc(noSolicitud)
          .get()
          .then((snap) => {
            var data = snap.data()["archivos"];
            Object.keys(data.documents).forEach(function (key) {
              if (key === file.name){
                delete data.documents[key];
              }
            });
            Firebase.firmaDigitalVidaMas()
              .doc("solicitudes_generadas")
              .collection(idPart)
              .doc(noSolicitud)
              .update({ archivos: data });
            var returnData = { type: "doc", data: data.documents };
            promesa(returnData);
          });
      });
    } else if (type === "img") {
      var taskImg = Firebase.firmaDigitalVidaMasFiles()
        .child(idPart)
        .child(noSolicitud)
        .child("Pictures")
        .child(file.name);
        taskImg.delete().then((snapshot) => {
        Firebase.firmaDigitalVidaMas()
          .doc("solicitudes_generadas")
          .collection(idPart)
          .doc(noSolicitud)
          .get()
          .then((snap) => {
            var data = snap.data()["archivos"];
            Object.keys(data.pictures).forEach(function (key) {
              if (key === file.name){
                delete data.pictures[key];
              };
            });
            Firebase.firmaDigitalVidaMas()
              .doc("solicitudes_generadas")
              .collection(idPart)
              .doc(noSolicitud)
              .update({ archivos: data });
            var returnData = { type: "img", data: data.pictures };
            promesa(returnData);
          });
      });
    }
  });
};

export const getRamos = () => {
  return Firebase.firmaDigitalVidaMas()
    .doc("configuraciones")
    .collection("ramos")
    .orderBy("index")
    .get();
};

export const getTiposDocumentos = () => {
  return Firebase.firmaDigitalVidaMas()
    .doc("configuraciones")
    .collection("documentos")
    .orderBy("index")
    .get();
};

export const getProductos = () => {
  return Firebase.firmaDigitalVidaMas()
    .doc("configuraciones")
    .collection("productos")
    .orderBy("index")
    .get();
};

export const getParentescos = () => {
  return Firebase.firmaDigitalVidaMas()
    .doc("configuraciones")
    .collection("parentescos")
    .orderBy("index")
    .get();
};

export const getRoles = () => {
  return Firebase.firmaDigitalVidaMas()
    .doc("configuraciones")
    .collection("roles")
    .get();
};

export const saveFilesFad = (files, noSolicitud, uid) => {
  return new Promise((promesa) => {
    var count = 0;
    var filesData = {};
    files.data.data.files.forEach((file) => {
      const type = getType(file.fileName);
      var task = Firebase.firmaDigitalVidaMasFiles()
        .child(uid)
        .child(noSolicitud)
        .child("FAD")
        .child(noSolicitud + "." + type);
      task
        .putString(file.base64, "base64", { contentType: type === "pdf" ? "application/pdf" : ""})
        .then((snapshot) => {
          task.getDownloadURL().then((url) => {
            filesData[type] = url;
            count = count + 1;
            if (count === files.data.data.files.length) {
              Firebase.firmaDigitalVidaMas()
                .doc("solicitudes_generadas")
                .collection(uid)
                .doc(noSolicitud)
                .get()
                .then((snap) => {
                  var data = snap.data()["archivos"];
                  data.FAD = filesData;
                  Firebase.firmaDigitalVidaMas()
                  .doc("solicitudes_generadas")
                  .collection(uid)
                  .doc(noSolicitud)
                  .update({ archivos: data });
                });
            promesa(true);
            }
          });
        });
    });
  });
};

function getType(fileName) {
  var type = "";
  if (fileName.endsWith(".pdf")) {
    type = "pdf";
  } else if (fileName.endsWith(".key")) {
    type = "key";
  } else if (fileName.endsWith(".fad")) {
    type = "fad";
  }
  return type;
}

function getFormatDate(date) {
  const ye = new Intl.DateTimeFormat("en", { year: "2-digit" }).format(date);
  const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(date);
  const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
  var formatDate = da + mo + ye;
  return formatDate;
}
