import React, { Fragment } from "react";
import { GlobalStyle } from "./GlobalStyles";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { FormularioFirmantes } from "./Pages/FormularioFirmantes";
import { FormularioSolicitud } from "./Pages/FormularioSolicitud";
import { Home } from "./Pages/Home";
import { PrivateRoute } from "./PrivateRoute";
import { Loader } from "./components/Generico/Loader";
import { Login } from "./Pages/Login";
import AppBarFD from "./components/Generico/AppBarFD";
import Footer from "./components/Generico/Footer";
import { VisorDocumentos } from "./Pages/VisorDocumentos";
import { Reportes } from "./Pages/Reportes";
export const App = () => (
  <Fragment>
    <GlobalStyle />
    <Loader />
    <AppBarFD />
    <BrowserRouter>
      <Switch>
        <PrivateRoute exact path="/" component={Home} />
        <PrivateRoute
          exact
          path="/nueva-solicitud"
          component={FormularioSolicitud}
        />
        <PrivateRoute
          exact
          path="/firmantes-solicitud"
          component={FormularioFirmantes}
        />
        <PrivateRoute
          exact
          path="/visor-documentos"
          component={VisorDocumentos}
        />
        <Route exact path="/reportes" component={Reportes} />
        <Route exact path="/iniciar-sesion" component={Login} />
        <Route path="*" component={Login} />
      </Switch>
    </BrowserRouter>
    <Footer />
  </Fragment>
);
