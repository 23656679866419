import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DropDown from "../components/Generico/DropDown";
import SnackBarAlert from "../components/Generico/SnackBarAlert";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { updateDataSolicitud } from "../Utils/FirebaseMethods";
import { SolicitudFdd } from "../components/Generico/SolicitudFDD";
import Firebase from "../Utils/Firebase";
import { showLoading } from "../hooks/showLoading";
import { checkisEmpty } from "../Utils/Tools";
import FilesTable from "../components/Generico/FilesTable";

const stylesGMM = makeStyles((theme) => ({
  containerGMM: {
    background: "#EEEEEE",
    flexDirection: "column",
    paddingBottom: "20px",
    paddingTop: "30px",
  },
  cardContainerGMM: {
    marginLeft: "2%",
    marginRight: "2%",
    flexdirection: "column",
  },
  areaDocumentosGMM: {
    padding: "2%",
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
  },
  areaBeneficiariosGMM: {
    padding: "2%",
    flexDirection: "column",
    display: "flex",
  },
  titleBeneficiariosGMM: {
    flexDirection: "row",
    alignContent: "center",
    alignItems: "flex-end",
    display: "flex",
    justifyContent: "space-between",
  },
  btnContinuarFirmantesGMM: {
    padding: "2%",
  }
}));

function useAsyncState(initialValue) {
  const [value, setValue] = useState(initialValue);
  const setter = (x) =>
    new Promise((resolve) => {
      setValue(x);
      resolve(x);
    });
  return [value, setter];
}

const stateSnack = {
  msjSnackbar: '',
  severitySnackBar: 'success',
  openSnackBar: false,
};

export default function FormularioGMM({
  idPart,
  solicitud,
  ramoSelected,
  documentosCat,
  props,
}) {
  const classes = stylesGMM();

  const [state, setState] = useState({
    lastChange: null,
    documents: {},
    pictures: {},
    documento: "",
    continueFirmantes: false,
    noSolicitud: solicitud,
  });
  const [snackbar, setSnack] = useAsyncState(stateSnack);
  const { openSnackBar, msjSnackbar, severitySnackBar } = snackbar;
  const {
    continueFirmantes,
    documents,
    pictures,
    noSolicitud,
    documento,
    lastChange,
  } = state;

  const fetchFirebase = useCallback(async () => {
    try {
      showLoading(true);
      const result = await Firebase.getData(idPart, noSolicitud);
      const { solicitud_data: { documento }, archivos: { documents, pictures } } = result.data();
      showLoading(false);
      setState({ ...state, documento, documents, pictures, data: result.data() });
    } catch (error) {
      showLoading(false);
    }
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (documento || lastChange) {
      setDataToFirestore();
    } else {
      fetchFirebase();
    }
  }, [documento, lastChange]);// eslint-disable-line react-hooks/exhaustive-deps

  function closeSnack() {
    setSnack();
  }

  function onChangeDocument(id, value) {
    setState({ ...state, documento: value });
  }

  function setDataToFirestore() {
    var data = { documento: documento, beneficiarios: [], importe: null };
    updateDataSolicitud(idPart, data, noSolicitud);
    validateForm();
  }

  function validateForm() {
    if (!checkisEmpty(ramoSelected) && !checkisEmpty(documento) && Object.keys(documents).length > 0) {
      setState({ ...state, continueFirmantes: true });
    } else {
      setState({ ...state, continueFirmantes: false });
    }
  }

  function continueToFirmantes() {
    props.history.push({ pathname: "/firmantes-solicitud", search: "", state: { noSolicitud: noSolicitud } });
  }

  function cancelToFirmantes() {
    setState({ ...state, continueFirmantes: false });
    props.history.push({ pathname: "/" });
  }

  function getDocuments() {
    var documentsRamo = [];
    documentosCat.forEach(documento => {
      if (documento.data().ramo === ramoSelected) {
        documentsRamo.push(documento);
      }
    });
    return documentsRamo;
  }

  return (
    <div>
      <div>
        <Grid container spacing={4} className={classes.areaDocumentosGMM}>
          <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
            <DropDown
              fullWidth
              label="Tipo de documento"
              items={getDocuments()}
              onChange={onChangeDocument}
              valueDrop={documento}
            />
          </div>
        </Grid>
        <Divider />
        <div className={classes.areaBeneficiariosGMM}>
          <FilesTable 
          idPart ={idPart} 
          noSolicitud ={noSolicitud}
          documents={documents} 
          pictures={pictures}
          state={state}
          setState={setState}
          setSnack={setSnack}
          />
        </div>
        <Divider />
          <div className={classes.btnContinuarFirmantesGMM}>
            <SolicitudFdd
              visible={continueFirmantes}
              title={"¡Los datos se encuentran completos!"}
              handleCancel={cancelToFirmantes}
              handleComplete={continueToFirmantes} />
          </div>
      </div>
      <SnackBarAlert
        isOpen={openSnackBar}
        message={msjSnackbar}
        closeSnack={closeSnack}
        severity={severitySnackBar}
      />
    </div>
  );
}
