import { PDFDocument } from 'pdf-lib';
import {
  X30,
  X40,
  X170,
  X300,
  addHeaders,
  mergePdfs,
  embedImages,
} from './Coordenadas';
export async function createPdf(solicitud, sesion) {
  let listaBen = '';
  const {
    solicitud_data: { beneficiarios, documento, importe, producto },
    firma_data: {
      cys,
      firmantes: { asesor, contratante, solicitante },
    },
    archivos: { documents, pictures },
  } = solicitud;
  const pdfDoc = await PDFDocument.create();
  await mergePdfs(pdfDoc, documents);
  await embedImages(pdfDoc, pictures);

  beneficiarios.forEach((key, val) => {
    val = val + 1;
    listaBen +=
      key[`nombres_${val}`] +
      ' ' +
      key[`primer_apellido_${val}`] +
      ' ' +
      key[`segundo_apellido_${val}`] +
      ' ' +
      key[`parentesco_${val}`] +
      ' ' +
      key[`porcentaje_${val}`] +
      '%';
    if (Object.keys(beneficiarios).length - 1 === val) {
      listaBen += ' y ';
    } else if (Object.keys(beneficiarios).length === val) {
      listaBen += ' ';
    } else {
      listaBen += ' , ';
    }
  });

  let page = pdfDoc.addPage();
  let pageAgente = pdfDoc.addPage();
  const { height } = page.getSize();

  page = addHeaders(page, height);
  page.drawText(
    `Ratifico que la información  personal o de terceros contenida en el formato de solicitud de seguro de
${producto} que se incorpora a la presente carta es cierta y está completa y que no padezco
ni he padecido alguna enfermedad grave, entre ellas diabetes e hipertensión,  dicha información fue
proporcionada por mí al Asesor Patrimonial, quien me explicó los derechos y obligaciones que entre
el solicitante y GNP Seguros surjan derivados de las instrucciones contenidas en la solicitud referida,
así como el contenido de las leyendas legales que forman parte de la misma, por lo tanto, confirmo
que estoy de acuerdo en contratar mi póliza ${producto}, con un descuento quincenal vía nómina o tarjeta de $${importe} y designó como beneficiarios a ${listaBen}.`,
    X30(height)
  );
  page.drawText(
    `
  ______________________________________
            Nombre y Firma del Solicitante
    ${cys ? contratante.nombre : solicitante.nombre}
    Correo: ${cys ? contratante.correo : solicitante.correo}
    Teléfono: ${cys ? contratante.telefono : solicitante.telefono}
  `,
    X40(height)
  );
  page.drawText(
    `
  ______________________________________
            Nombre y Firma del Contratante
    ${contratante.nombre}
    Correo: ${contratante.correo}
    Teléfono: ${contratante.telefono}
  `,
    X300(height)
  );
  /**
   * Agente
   */
  pageAgente = addHeaders(pageAgente, height);
  const p1 = `${asesor.nombre} Asesor Patrimonial de GNP, declaro que realicé una entrevista personal con el`;
  const p2 = `Contratante o Representante Legal que firma el formato (${documento}), con la finalidad de obtener los`;
  const p3 = 'datos personales que fueron asentados en el formato. Así mismo, validé los documentos de identificación exhibidos por éste.'
  pageAgente.drawText(
    `${p1} ${p2} ${p3}`,
    X30(height)
  );

  pageAgente.drawText(
    `
  __________________________________________
                    Nombre y Firma del Agente
    ${asesor.nombre}
  `,
    X170(height)
  );

  const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
  return {
    pdfDataUri,
    numPag: pdfDoc.getPageCount(),
  };
}
