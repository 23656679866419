export const FORMULARIO_FIRMANTES = {
  init_snack: {
    msjSnackbar: '',
    severitySnackBar: 'success',
    openSnackBar: false,
  },
  obtener_datos_solicitud: {
    msjSnackbar: 'Ocurrio un error al obtener los datos de tu solicitud',
    severitySnackBar: 'error',
    openSnackBar: true,
  },
  comprimir_archivo: {
    msjSnackbar: 'Ocurrio un error al comprimir el archivo',
    severitySnackBar: 'warning',
    openSnackBar: true,
  },
  error_generar_archivo: {
    msjSnackbar: 'Ocurrio un error al generar el archivo',
    severitySnackBar: 'error',
    openSnackBar: true,
  },
  error_proceso_firma: {
    msjSnackbar: 'Ocurrio un error en el proceso de firma',
    severitySnackBar: 'error',
    openSnackBar: true,
  },
  solicitud_enviada: {
    msjSnackbar: 'La solicitante se envio a firma correctamente',
    severitySnackBar: 'success',
    openSnackBar: true,
  },
  nombres_duplicados: {
    msjSnackbar: 'Verifica los datos, los nombres no pueden repetirse.',
    severitySnackBar: 'warning',
    openSnackBar: true,
  },
  corroes_duplicados: {
    msjSnackbar: 'Verifica los datos, los correos no pueden repetirse.',
    severitySnackBar: 'warning',
    openSnackBar: true,
  },
  telefonos_duplicados: {
    msjSnackbar: 'Verifica los datos, los teléfonos no pueden repetirse.',
    severitySnackBar: 'warning',
    openSnackBar: true,
  },
};

export const HOME = {
  init_snack: {
    msjSnackbar: '',
    severitySnackBar: 'success',
    openSnackBar: false,
  },
  firma_completada: {
    msjSnackbar: 'El proceso de firma se completó correctamente, se procederá a obtener la carta firmada',
    severitySnackBar: 'success',
    openSnackBar: true,
  },
  estado_no_disponible: {
    msjSnackbar: 'No se pudo obtener el estado en este momento',
    severitySnackBar: 'error',
    openSnackBar: true,
  },
  estado_desconocido: {
    msjSnackbar: "Estado desconocido",
    severitySnackBar: 'warning',
    openSnackBar: true,
  },
  error_archivos: {
    msjSnackbar: 'No se pudieron obtener los archivos',
    severitySnackBar: 'error',
    openSnackBar: true,
  },
};

export const FORMULARIO_PRINCIPAL = {
  init_snack: {
    msjSnackbar: '',
    severitySnackBar: 'success',
    openSnackBar: false,
  },
  error_archivo_adjunto: {
    msjSnackbar: "Ocurrió un error al adjuntar el archivo",
    severitySnackBar: "error",
    openSnackBar: true,
  },
  error_eliminar_archivo: {
    msjSnackbar: "Ocurrió un error al eliminar el archivo",
    severitySnackBar: "error",
    openSnackBar: true,
  },
  warning_beneficiarios: {
    msjSnackbar: "Solo es posible agregar 6 beneficiarios",
    severitySnackBar: "warning",
    openSnackBar: true,
  },
};