import {
  SERVICE_LOGIN,
  FIREBASE_CONFIG,
  SERVICE_LOGIN_FAD, // Crear cloud function de este proyecto
  SERVICE_FILES_FAD,
  SERVICE_STATUS_FAD,
  SERVICE_REQUISITION_FAD,
  SERVICE_COMPRESOR,
  SERVICE_LTPA_TOKEN,
  SERVICE_GET_COLLECTIONS,
} from '../properties';
import axios from 'axios';
import Firebase from './Firebase';
const TYPE = 'application/json';

export const initSessionService = async (correo, password) => {
  const resultInter = await login(correo, password, 'intermediarios');
  if (resultInter == null) {
    return await login(correo, password, 'empleados');
  } else {
    return resultInter;
  }
};

export const login = async (correo, password, usuario) => {
  try {
    const result = await axios.post(SERVICE_LOGIN, {
      mail: correo,
      password: password,
      projectid: FIREBASE_CONFIG.projectId,
      tipousuario: usuario,
    });
    const jwt = result.data.jwt;
    const user = await Firebase.login(jwt);
    return { user: user.user, authData: result.data };
  } catch (error) {
    return null;
  }
};

export const createLtpaToken = async (cookie) => {
  try {
    const result = await axios.post(SERVICE_LTPA_TOKEN, {
      cookie: cookie,
    });
    const jwt = result.data.jwt;
    const user = await Firebase.login(jwt);
    if (user.message) {
      return null;
    } else {
      return { user: user.user, authData: result.data };
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const obtenerCredenciales = async () => {
  try {
    const result = await axios.get(SERVICE_LOGIN_FAD);
    return result;
  } catch (error) {
    console.log('obtenerCredenciales error', error);
    return null;
  }
};

export const obtenerArchivos = async (reqId) => {
  const auth = await obtenerCredenciales();
  let files = [];
  try {
    files = await axios.get(SERVICE_FILES_FAD + reqId, {
      headers: {
        'Content-Type': TYPE,
        Authorization:
          auth.data.data.token_type + ' ' + auth.data.data.access_token,
      },
    });
    return files;
  } catch (error) {
    console.log('obtenerArchivos error', error);
    return null;
  }
};

export const obtenerEstatusFAD = async (idRequisition) => {
  try {
    const auth = await obtenerCredenciales();
    const result = await axios.get(SERVICE_STATUS_FAD + idRequisition, {
      headers: {
        'Content-Type': TYPE,
        Authorization:
          auth.data.data.token_type + ' ' + auth.data.data.access_token,
      },
    });
    return result;
  } catch (error) {
    console.log('obtenerEstatusFad error', error);
    return null;
  }
};

export const comprimirPDF = async (b64, name) => {
  try {
    const config = {
      method: 'post',
      url: SERVICE_COMPRESOR,
      headers: {
        'Content-Type': TYPE,
      },
      data: {
        quality: 'ebook',
        file: false,
        name: name,
        base64: b64,
      },
    };

    const result = await axios(config);
    return result;
  } catch (error) {
    console.log('comprimirPDF error', error);
    return null;
  }
};
export const crearReqFad = async (xml, pdf, hash, auth) => {
  try {
    const data = new FormData();
    data.append('xml', xml);
    data.append('pdf', pdf);
    data.append('hash', hash);

    const config = {
      method: 'post',
      url: SERVICE_REQUISITION_FAD,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: auth,
      },
      data: data,
    };

    const result = await axios(config);
    return result;
  } catch (error) {
    console.log('Crear requisition error', error);
    return error;
  }
};

export const getCollections = async () => {
  try {
    const result = await axios.post(SERVICE_GET_COLLECTIONS, {});
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
};
