import React, { useState, useEffect} from 'react';
import TableRow from '@material-ui/core/TableRow';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Hidden from '@material-ui/core/Hidden';
import RowSolicitud from './RowSolicitud';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Search';
import folderImagePATH from '../../assets/files_folder.png';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.white,
      color: '#1A237E',
    },
    body: {
      fontSize: 14,
    },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
    solicitudesContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    searchContainer: {
      paddingBottom: '1%',
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
    },
    noDocumentsContainer: {
      paddingTop: '10%',
      paddingBottom: '20%',
      textAlign: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      display: 'flex',
    },
  }));

export default function HomeTable({solicitudesFirebase, getActions, showDialogEliminar}) {
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState(solicitudesFirebase);
    const classes = useStyles();

    useEffect(() => {
        setSearch(solicitudesFirebase);
    },[solicitudesFirebase]);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    function onSearch(e) {
        setPage(0);
        var value = e.target.value.toUpperCase();
        if (value === '') {
          setSearch(solicitudesFirebase);
        } else {
          var searchList = [];
          solicitudesFirebase.forEach((solicitud) => {
            if (solicitud.no_solicitud.includes(value)) {
              searchList.push(solicitud);
            } else if (solicitud.firma_data.asesor) {
              if (
                solicitud.firma_data.firmantes.asesor.nombre
                  .toUpperCase()
                  .includes(value)
              ) {
                searchList.push(solicitud);
              }
            }
          });    
          setSearch(searchList);
        }
      }

    if(solicitudesFirebase.length > 0){
        return(
            <div className={classes.solicitudesContainer}>
          <div className={classes.searchContainer}>
            Solicitudes realizadas:
            <TextField
              id="busqueda"
              label="Búsqueda..."
              variant="outlined"
              size="small"
              onChange={onSearch}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {' '}
                    <SearchIcon />{' '}
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <TableContainer
            component={Paper}
            style={{
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: '#B0BEC5',
            }}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>No. de Solicitud</StyledTableCell>
                  <StyledTableCell align="left">Ramo</StyledTableCell>
                  <Hidden smDown>
                    <StyledTableCell align="left">
                      Tipo de documento
                    </StyledTableCell>
                  </Hidden>
                  <Hidden smDown>
                    <StyledTableCell align="left">
                      Asesor Patrimonial
                    </StyledTableCell>
                  </Hidden>
                  <Hidden smDown>
                    <StyledTableCell align="left">Fecha</StyledTableCell>
                  </Hidden>
                  <Hidden smDown>
                    <StyledTableCell align="left">Estatus</StyledTableCell>
                  </Hidden>
                  <StyledTableCell align="left">Opciones</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {search
                  .slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                  .map((solicitud) => (
                    <RowSolicitud
                      key={solicitud.no_solicitud}
                      solicitud={solicitud}
                      onClickPrincipal={getActions(solicitud)}
                      onClickSecondary={(e) =>
                        showDialogEliminar(solicitud.no_solicitud, e)
                      }
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25]}
            component="div"
            count={search.length}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={'Filas por página'}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count}`
            }
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
        );
    }

    return(
        <div className={classes.noDocumentsContainer}>
          <img
            style={{ height: '172px', width: '214px', paddingBottom: '2%' }}
            src={folderImagePATH}
            alt="documentos"
          />
          <Typography variant="h5" className={classes.title}>
            {'Aún no tienes documentos'}
          </Typography>
        </div>
    );  
}