import React from 'react';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { getCollections } from '../Utils/Service';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Firebase from '../Utils/Firebase';
import TablaBusqueda from '../components/Generico/TablaBusqueda';
import SnackBarAlert from '../components/Generico/SnackBarAlert';
import { showLoading } from '../hooks/showLoading';

const useStyles = makeStyles((theme) => ({
  card: {
    marginLeft: '2%',
    marginRight: '2%',
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export const VisorDocumentos = () => {
  const classes = useStyles();
  const [criterio, setCriterio] = React.useState('');
  const [value, setValue] = React.useState('');
  const [snack, setSnack] = React.useState({
    msjSnackbar: 'No se obtuvieron resultados.',
    severitySnackBar: 'warning',
    openSnackBar: false,
  });
  const [input, setInput] = React.useState({
    label: 'Número de solicitud',
  });

  const [searchData, setData] = React.useState([]);

  const handleChange = (event) => {
    switch (event.target.value) {
      case 'NS':
        setInput({ label: 'Número de solicitud' });
        break;
      case 'AP':
        setInput({ label: 'Nombre del Asesor Comercial' });
        break;
      default:
        setInput({ label: 'Enlace que inició el proceso de solicitud' });
        break;
    }
    setValue('');
    setCriterio(event.target.value);
  };
  const handleInputChange = (event) => {
    setValue(event.target.value);
  };

  const closeSnack = () => {
    setSnack({
      msjSnackbar: 'No se obtuvieron resultados.',
      severitySnackBar: 'warning',
      openSnackBar: false,
    });
  };

  const consultaPorSolicitud = async (uids) => {
    const data = [];
    for (let index = 0; index < uids.length; index++) {
      const element = uids[index];
      const query = Firebase.searchData()
        .collection(element)
        .where('no_solicitud', '==', value);

      query.get().then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          if (doc.data().estatus_solicitud === 'Firmada') {
            data.push(doc.data());
          }
        });

        if (index === uids.length - 1) {
          setData(data);
          showLoading(false);
          if (data.length === 0) {
            setSnack({ ...snack, openSnackBar: true });
          }
        }
      });
    }
  };

  const estatusFirmada = (data) => {
    if (data.estatus_solicitud === 'Firmada') {
      if (
        (criterio === 'EA' && data.agente_data.nombre.includes(value)) ||
        (criterio === 'AP' &&
          data.firma_data.hasOwnProperty('firmantes') &&
          data.firma_data.firmantes.asesor.nombre.includes(value))
      ) {
        return data;
      }
    }
    return {};
  };

  const consultaNombre = async (uids) => {
    const data = [];
    for (let index = 0; index < uids.length; index++) {
      const element = uids[index];
      const query = Firebase.searchData()
        .collection(element)
        .orderBy('date', 'desc');

      query.get().then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          const solicitud = estatusFirmada(doc.data());
          if (Object.keys(solicitud).length > 0) {
            data.push(solicitud);
          }
        });

        if (index === uids.length - 1) {
          setData(data);
          showLoading(false);
          if (data.length === 0) {
            setSnack({ ...snack, openSnackBar: true });
          }
        }
      });
    }
  };

  const handleClick = async (event) => {
    showLoading(true);
    setData([]);
    const lista = await getCollections();
    const uids = lista !== undefined ? lista.data : [];

    if (criterio === 'NS') {
      consultaPorSolicitud(uids);
    } else {
      consultaNombre(uids);
    }
  };
  return (
    <Card className={`${classes.card} container-pages`}>
      <Grid container direction="row" alignItems="center" spacing={4}>
        <Grid item xs={12} sm={3}>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel id="criterios-busqueda">
              Criterios de búsqueda
            </InputLabel>
            <Select
              labelId="criterios-busqueda"
              id="busqueda-select"
              onChange={handleChange}
              value={criterio}
            >
              <MenuItem value={'NS'}>Número de solicitud</MenuItem>
              <MenuItem value={'AP'}>Asesor patrimonial</MenuItem>
              <MenuItem value={'EA'}>Enlace administrativo</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label={input.label}
            required
            name="inputSearch"
            onChange={handleInputChange}
            value={value}
            disabled={criterio === ''}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleClick}
            endIcon={<SearchIcon>Buscar</SearchIcon>}
            disabled={value === ''}
          >
            Buscar
          </Button>
        </Grid>
      </Grid>

      {searchData.length > 0 && <TablaBusqueda data={searchData} />}
      <SnackBarAlert
        severity={snack.severitySnackBar}
        isOpen={snack.openSnackBar}
        message={snack.msjSnackbar}
        closeSnack={closeSnack}
      />
    </Card>
  );
};
