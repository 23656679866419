import React from "react";
import Button from '@material-ui/core/Button';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import BackIcon from '@material-ui/icons/ArrowBack';

export default function HomeButton({props, goHome}){

    function home() {
        props.history.push({
            pathname: "/",
        });
    }

    function back() {
        window.history.back();
    }

    return (
        <Button
            style={{
                backgroundColor: "#FFFFFF",
                '&:hover': {
                    backgroundColor: "#FFFFFF",
                }
            }}
            color={goHome ? "secondary" :  "primary"}
            startIcon={goHome ? <HomeIcon /> : <BackIcon/>}
            onClick={goHome ? home : back}
            disableRipple
        >
            {goHome ? "Inicio" : "Regresar"}
        </Button>
    );
}
