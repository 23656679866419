import styled from "styled-components";

export const Card = styled.div`
  box-shadow: 0px 2px 0px #999999;
  border-radius: 1px;
  display: flex;
  flex-direction: column;
  height: 50px;
  margin: 0 auto;
  width: 96%;
`;

export const Title = styled.span`
  align-items: center;
  color: #0c2040;
  display: flex;
  height: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
  letter-spacing: -0.075px;
  margin: 0 auto;
  margin-top: 18px;
  margin-bottom: 18px;
  text-align: center;
  width: 220px;
`;

export const Consecutive = styled.span`
  align-items: center;
  color: #6a6666;
  display: flex;
  font-weight: normal;
  letter-spacing: -0.075px;
  margin-left: 14px;
  text-align: center;
`;

export const ListCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  display: flex;
  height: 80px;
  left: 26px;
  margin: 20px auto;
  top: 332px;
  width: 333px;
`;

export const Image = styled.img`
  border: 1px solid #ddd;
  box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  object-fit: cover;
  height: 50px;
  margin: 17px 0 0 25px;
  width: 50px;
`;

export const Description = styled.div`
  align-items: center;
  color: #183e7b;
  display: flex;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  max-height: 39px;
  max-width: 190px;
  margin: calc(39px / 2) 0 0 30px;
  text-align: center;
`;

export const CardPlan = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  min-height: 120px;
  margin: 20px auto;
  text-align: center;
  width: 355px;
`;
export const CardLogiImage = styled.div`
  border: 3px solid #ff6d00;
  background: #ffffff;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  max-height: 250px;
  margin: 30px auto;
  text-align: center;
  width: 100%;
`;

export const Wrapper = styled.div`
  margin: 21px auto;
  min-width: 208px;
  max-width: 300px;
  height: 30px;
  line-height: 30px;
`;

export const WrapperDescription = styled.div`
  margin: 20px 0;
`;

export const TitlePlan = styled.h1`
  color: #183e7b;
  font-family: Arial;
  font-size: 20px;
  text-align: left;
  text-transform: uppercase;
  margin-left: 45px;
`;
export const TitleLogin = styled.div`
  background: #2c4f86;
  box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.14), 0 15px 10px -5px #8f8f8f;
  border-radius: 5px;
  color: #fff;
  font-family: Arial;
  font-size: 24px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  height: 60px;
  padding: 15px;
  bottom: -30px;
  margin: 0 auto;
  width: 90%;
  @media (max-width: 768px) {
    height: 40px;
    font-size: 16px;
  }
`;

export const DescriptionPlan = styled.div`
  vertical-align: middle;
  color: #ff6b0b;
  display: inline-flex;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  max-width: 75px;
  margin: 0 21px 21px;
  &:visited,
  :focus,
  :hover {
    color: #ffede3;
  }
  &:nth-child(2)::after {
    border: 1px solid #183e7b;
    content: "";
    margin: 0 0 0 20px;
    min-height: 20px;
  }
  &:nth-child(5)::after {
    border: 1px solid #183e7b;
    content: "";
    margin: 0 0 0 20px;
    min-height: 20px;
  }
  &:nth-child(2)::before {
    border: 1px solid #183e7b;
    content: "";
    margin: 0 20px 0 0;
    min-height: 20px;
  }
  &:nth-child(5)::before {
    border: 1px solid #183e7b;
    content: "";
    margin: 0 20px 0 0;
    min-height: 20px;
  }
`;
export const ImagePlan = styled.img`
  float: left;
  height: 30px;
  width: 30px;
`;

export const ImageLogin = styled.img`
  width: 100%;
  height: 185px;
`;

export const RadioList = styled.div`
  width: 210px;
  height: 180px;
  display: inline-flex;
`;
export const Input = styled.input`
  width: 61px;
  height: 20px;
  left: 32px;
  top: 293px;
`;

export const ButtonNormal = styled.button`
  width: 141px;
  height: 32px;
  background: #ff6f20;
  border-radius: 3px;
  margin: 20px auto;
  display: block;
`;
export const ButtonCancelar = styled.button`
  width: 141px;
  height: 32px;
  background: #fff;
  border: 1px solid #ff6f20;
  border-radius: 3px;
  margin: 20px auto;
  display: block;
`;

export const LabelButton = styled.label`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.0875px;
  color: #ffffff;
`;

export const LabelTitle = styled.label`
  height: 32px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  color: #4d4d4d;
`;
