const js2xmlparser = require('js2xmlparser');

export function fadXML(data, pdf) {
  const page = pdf.numPag;
  const {
    no_solicitud,
    agente_data: { uid },
  } = data;

  const signers = getSigners(data, page);
  const reqFAD = {
    contractName: 'Solicitud de movimientos',
    acceptanceVideoNotRequired: 'true',
    showMessageApp: 'false',
    acceptanceLegend: `"Yo NombreDelFirmante, confirmó que los datos proporcionados en la "Carta solicitud de movimientos",
    son correctos y veraces para generar una modificación a mi póliza de Vida Individual, FechaDeFirma."`,
    validity: '15',
    reference: 'gnp.1234',
    idDocument: `${no_solicitud}-${uid}`,
    contractType: 'Contrato',
    signOnWeb: 'true',
    certificate: {
      page: `${page}`,
      positionX1: '2.24565',
      positionX2: '23.43795',
      positionY1: '93.6258',
      positionY2: '96.56556',
    },

    signers: signers,
  };

  //return reqFAD;

  const xml = js2xmlparser.parse('requisition', reqFAD, {
    declaration: { standalone: 'yes', encoding: 'UTF-8' },
  });
  return xml;
}

const getSigners = (sgn, pg) => {
  const {
    firma_data: { cys, firmantes },
  } = sgn;
  const signers = [];
  let signer;
  const order = [
    {
      // Orden 1 Asesos
      centerX: '48.96755',
      centerY: '85.060735',
      optional: false,
      positionX1: '28.7476',
      positionX2: '69.1875',
      positionY1: '82.9234',
      positionY2: '87.19807',
      page: `${pg}`,
      signerType: 'Firmante',
    },
    {
      // Orden 2 Contratante

      centerX: '68.94795',
      centerY: '85.12863499999999',
      optional: false,
      positionX1: '50.5287',
      positionX2: '87.3672',
      positionY1: '82.9707',
      positionY2: '87.28657',

      page: `${pg - 1}`,
      signerType: 'Firmante',
    },
    {
      // Orden 3 Solicitante
      centerX: '24.99461',
      centerY: '85.176905',
      optional: false,
      positionX1: '6.90771',
      positionX2: '43.08151',
      positionY1: '83.1683',
      positionY2: '87.18551000000001',
      page: `${pg - 1}`,
      signerType: 'Firmante',
    },
  ];
  const orderCYS = [order[1], order[2]];
  try {
    Object.keys(firmantes).map(function (key) {
      let orden = 1;
      if (firmantes[key].id === 'asesor') {
        orden = 1;
      } else if (firmantes[key].id === 'contratante') {
        orden = 2;
      } else {
        orden = 3;
      }

      signer = {
        signerName: firmantes[key].nombre,
        mail: firmantes[key].correo,
        phone: `+521${firmantes[key].telefono}`,
        signDevicePhone: `+521${firmantes[key].telefono}`,
        authenticationType: 'Código de Seguridad',
        authenticationData: '1234',
        order: orden,
        signatures:
          firmantes[key].id === 'contratante' && cys
            ? orderCYS
            : order[orden - 1],
      };
      if (
        firmantes[key].nombre !== '' &&
        firmantes[key].correo !== '' &&
        firmantes[key].telefono !== ''
      ) {
        signers.push(signer);
      }
      return signers;
    });
    return signers;
  } catch (error) {
    return error;
  }
};
