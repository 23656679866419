import React, { useContext } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Phone from "@material-ui/icons/Phone";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import gnpLogo from "../../assets/logo-gnp.png";
import { Context } from "../../Context";

const useStyles = makeStyles((theme) => ({
  containerAgenteData: {
    justifyContent: "space-between",
    paddingBottom: "10px",
    paddingTop: "10px",
    background: "#FFFFFF",
    color: "#000000",
  },
  areaLogo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  containerFirmaDigital: {
    background: theme.palette.secondary.main,
    justifyContent: "space-between",
  },
  areaDataAgente: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

export default function AppBarFD(props) {
  const { isAuth, closeSesion, sesionData } = useContext(Context);
  const [anchorEl, setAnchorEl] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(false);
  };
  const handleCloseSesion = () => {
    setAnchorEl(false);
    closeSesion();
  };

  const classes = useStyles();
  return (
    <div style={{ paddingBottom: "120px" }}>
      <AppBar position="absolute">
        <Toolbar className={classes.containerAgenteData}>
          <div className={classes.areaLogo}>
            <img src={gnpLogo} height="60px" width="160" alt={"GNP"}/>
            <Typography variant="subtitle1" style={{ margin: "10px" }}>
              Firma Digital de Documentos
            </Typography>
          </div>

          {isAuth ? (
            <div className={classes.areaDataAgente}>
              <Phone />
              <div style={{ marginLeft: "10px" }}>
                <Typography variant="subtitle1">
                  Nombre: {sesionData.name}
                </Typography>
                <Typography variant="subtitle1">
                  Id Participante: {window.sessionStorage.getItem("uid")}
                </Typography>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </Toolbar>
        <Toolbar className={classes.containerFirmaDigital}>
          <Typography variant="h6">Firma Digital</Typography>
          {isAuth && (
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleCloseSesion}>Cerrar sesión</MenuItem>
                {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}
