import React from "react";
import {
  Card,
  Title,
  Consecutive,
  ListCard,
  Image,
  Description,
  CardPlan,
  CardLogiImage,
  Wrapper,
  WrapperDescription,
  TitlePlan,
  TitleLogin,
  ImagePlan,
  ImageLogin,
  DescriptionPlan,
  ButtonNormal,
  LabelButton,
} from "./styles";

const DEFAULT_IMAGE = "https://i.imgur.com/dJa0Hpl.jpg";
const titles = ["Privilegio", "Platino", "Personal"];

export const CardFolio = ({ solicitud = "11291000001" }) => (
  <Card>
    <Title>
      No solicitud <Consecutive>{solicitud}</Consecutive>
    </Title>
  </Card>
);

export const MenuHome = () => {
  return [
    "Carta solicitud seguros",
    "Finalizar Solicitud de Seguro (En base a Carta Firmada)",
    "Solicitud de Seguro (Sin Carta Firmada)",
  ].map((category) => (
    <ListCard key={category}>
      <Image src={DEFAULT_IMAGE} />
      <Description>{category}</Description>
    </ListCard>
  ));
};

export const CardImage = ({ imgURL = DEFAULT_IMAGE, title = "Protección" }) => (
  <CardLogiImage>
    <ImageLogin src={imgURL} />
    <TitleLogin>{title}</TitleLogin>
  </CardLogiImage>
);

export const PlanesCard = ({
  imgURL = DEFAULT_IMAGE,
  title = "Protección",
  listTitle = titles,
}) => (
  <CardPlan>
    {console.log(typeof listTitle)}
    <Wrapper>
      <ImagePlan src={imgURL} />
      <TitlePlan>{title}</TitlePlan>
    </Wrapper>
    <WrapperDescription className={listTitle.length}>
      {listTitle.map((val, k) => (
        <DescriptionPlan key={val} className={"nth-" + k}>
          {val}
        </DescriptionPlan>
      ))}
    </WrapperDescription>
  </CardPlan>
);

//  return ["Vida", "Daños", "Autos", "GMM"].map((ramo) => (

export const ButtonCancelar = ({ label = "Cancelar" }) => (
  <ButtonCancelar>
    <LabelButton>{label}</LabelButton>
  </ButtonCancelar>
);

export const CardEnviarFDD = ({ label = "Enviar" }) => (
  <ButtonNormal>
    <LabelButton>{label}</LabelButton>
  </ButtonNormal>
);

export const LabelTitle = ({ title = "Titulo" }) => (
  <LabelTitle>{title}</LabelTitle>
);
