import React, { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const styles = (theme) => ({
  div: {
    margin: 0,
    minWitdh: '48px',
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(2),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
  },
}))(MuiDialogContent);

export default function ConfirmFile({
  isOpen,
  title,
  subtitle,
  titleDialog,
  src,
  size,
  type,
  handleComplete,
  handleCancel,
  handleSendFDD,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(isOpen);
  //eslint-disable-next-line
  useEffect(() => {
    setOpen(isOpen);
  });
  const handleClickOpen = () => {
    handleComplete();
  };
  const handleClose = () => {
    setOpen(false);
    handleCancel();
  };
  const handleSend = () => {
    setOpen(false);
    handleSendFDD();
  };

  let tipoMsg = 'error';
  if (size <= 4) {
    tipoMsg = 'success';
  } else if (size < 5) {
    tipoMsg = 'warning';
  }
  return (
    <div>
      <Grid container direction="row" justify="flex-start" alignItems="center">
        <Grid item xs={12} className={classes.margin}>
          <h3>{title}</h3>
          <p>{subtitle}</p>
        </Grid>
        <Button
          variant="contained"
          size="small"
          color="primary"
          id="siguiente"
          className={classes.margin}
          onClick={handleClickOpen}
        >
          Siguiente
        </Button>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          id="cancelar"
          className={classes.margin}
          onClick={handleClose}
        >
          Cancelar
        </Button>
      </Grid>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {titleDialog}
        </DialogTitle>
        <div id="message" className={`message-${tipoMsg}`}>
          Tamaño máximo de archivos adjuntos 5MB, tamaño del archivo {size}{' '}
          {type}.
        </div>
        <DialogContent dividers>
          <iframe title={'Preview'} src={src} id="pdf"></iframe>
        </DialogContent>
        <MuiDialogActions>
          <Button
            autoFocus
            disabled={size <= 5 ? false : true}
            onClick={handleSend}
            variant="outlined"
            size="small"
            color="primary"
          >
            Enviar
          </Button>
          <Button
            autoFocus
            onClick={handleClose}
            variant="outlined"
            size="small"
            color="primary"
          >
            Cancelar
          </Button>
        </MuiDialogActions>
      </Dialog>
    </div>
  );
}
