import React from 'react';

export const Loader = () => {
  return (
    <div id="content-loader">
      <div className="gnp-loading">
        <div className="circle-orange"></div>
        <div className="circle-blue"></div>
        <div className="logo">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="94"
            height="35"
            viewBox="0 0 94 35"
          >
            {' '}
            <g fill="none" fillRule="evenodd">
              {' '}
              <path
                fill="#183D7B"
                d="M42.086 16.762c0-.964.174-1.308-.915-1.882l-.754-.368v-.665h7.512v.665l-.766.299c-1.136.46-1.136.585-1.112
                1.951l.011 5.498-.278.264c-1.31.333-4.37 1.159-7.13
                1.159-6.328 0-9.446-3.248-9.446-10.272 0-7.553 4.405-10.824
                10.189-10.824 1.553 0 4.37 0 6.329 1.642.65.54.707 1.147.707
                1.388 0 .46-.093.551-.185.735l-1.403 2.697h-.522c0-1.331-1.564-4.878-5.633-4.878-4.718 0-4.95
                3.26-4.95 8.769 0 4.82.51 9.159 6.144 9.159 1.82 0 2.156-.952 2.202-2.02v-3.317M49.806
                22.615l1.345-.539c1.101-.425 1.101-1.217
                1.101-2.973V6.443c0-.975-.278-1.457-1.043-1.973l-1.229-.815v-.666h5.576l.336.207c.614.815
                1.89 3.007 3.605 5.302l5.912 7.966c.244.332.371.482.487.482.243 0
                .243-.299.243-.85v-9.63c0-1.388-.023-1.663-1.217-2.145l-1.75-.666v-.666h7.013v.666l-.893.333c-1.31.516-1.564.815-1.564
                2.812v11.936c0 1.825.034 3.673.034
                4.304l-.208.241h-1.716l-.336-.24c-.278-.334-1.867-2.549-3.49-4.695l-7.163-9.48c-.394-.517-.58-.792-.73-.792-.279
                0-.279.31-.279.734v10.835c0 1.457 0 1.917 1.287 2.341l1.808.631v.666h-7.129v-.666M71.982
                22.615l1.344-.447c1.009-.333 1.043-.884 1.043-2.789V6.283c0-1.446
                0-1.813-1.043-2.146l-1.344-.482v-.666h10.143c3.547 0 7.024.758 7.024 5.36 0
                4.212-3.176 6.818-8.137 6.818-.21 0-.487-.035-.672-.035l-.453-1.09.244-.241c2.226-.057
                4.926-.964 4.544-5.222-.302-3.317-1.276-3.937-4.022-3.937-1.867 0-1.994.275-1.994
                1.033v13.704c0 2.031-.035 2.456 1.193 2.789l1.589.447v.666h-9.46v-.666M29.208 26.502h64.468v-.5H29.208z"
              />{' '}
              <path
                fill="#FF6F20"
                d="M16.619 13.032c0 1.87-1.53 3.386-3.431 3.386-1.89 0-3.42-1.515-3.42-3.386
                0-1.871 1.53-3.386 3.42-3.386 1.9 0 3.43 1.515 3.43 3.386M7.496 7.373H.1l1.496
                4.534h3.686v-.011c3.733 0 6.758-2.996 6.77-6.703V1.554L7.496.074v7.3M7.473
                18.679v7.31l4.579-1.468v-3.65h-.012c0-3.696-3.025-6.691-6.77-6.703H1.596L.1
                18.678h7.373M18.89 18.69h7.396l-1.507-4.522h-3.686c-3.732.012-6.758 3.007-6.758
                6.703v3.638l4.556 1.48V18.69M18.902 7.396V.086l-4.567 1.468v3.65c.012 3.696 3.037 6.692
                6.77 6.703h3.674l1.507-4.51h-7.384"
              />{' '}
              <path
                fill="#183D7B"
                d="M91.99 3.712v.276h-.302v.55h.302v.402l-.082-.126h-.22v.826h-.36V3.712h.662zm0
                 2.342c-.72 0-1.299-.574-1.299-1.366 0-.804.58-1.366 1.299-1.366v-.333c-.951
                  0-1.716.758-1.716 1.699 0 .93.765 1.687 1.716 1.687v-.321zm0-1.114V4.54H92c.22 0
                   .394-.035.394-.287 0-.23-.209-.264-.383-.264h-.022v-.276h.057c.464 0 .719.16.719.563 0
                    .332-.197.493-.51.516l.522.85h-.371l-.417-.7zm0-1.618c.706 0 1.286.562 1.286 1.366 0
                     .792-.58 1.366-1.286 1.366v.32c.938 0 1.703-.756 1.703-1.686a1.7 1.7 0 0
                      0-1.703-1.699v.333zM30.762 34.992c-.724
                       0-1.173-.203-1.339-.296-.15-.172-.252-.733-.252-1.264.055-.101.236-.109.3-.023.157.507.685
                        1.224 1.354 1.224.583 0 .85-.397.85-.787
                         0-.632-.598-1.006-1.07-1.24-.567-.28-1.19-.749-1.19-1.52-.008-.882.686-1.49
                          1.828-1.49.26 0 .582.031.897.125.103.03.174.046.268.062.063.172.142.593.142
                           1.115-.04.094-.236.102-.307.024-.134-.398-.473-.967-1.04-.967-.52
                            0-.803.335-.803.725 0 .359.323.686.716.889l.52.273c.489.257 1.11.71 1.11 1.567
                             0 .96-.795 1.583-1.984 1.583M39.173 31.748c0 .219.008.234.22.234h.544c.41
                              0 .504-.054.583-.429l.04-.195c.054-.062.251-.062.29.008-.015.25-.023.53-.023.827
                               0 .296.008.577.024.826-.04.07-.237.078-.292.008l-.04-.195c-.078-.374-.172-.437-.582-.437h-.543c-.213
                                0-.22.008-.22.242v.928c0 .359.03.608.125.74.103.141.252.204.827.204.685
                                 0 .953-.024 1.386-.905.063-.086.26-.055.292.04-.032.404-.22
                                  1.036-.331 1.247a58.632 58.632 0 0 0-1.82-.024H38.63c-.378
                                   0-.764.008-1.355.024-.063-.047-.063-.234
                                    0-.273l.37-.055c.355-.054.402-.101.402-.819v-2.908c0-.71-.047-.757-.402-.827l-.197-.039c-.07-.046-.063-.226.016-.273.37.016.756.024
                                     1.134.024h1.67c.504 0 .953-.008 1.055-.024.008.125.063.687.103
                                      1.209-.032.086-.213.101-.292.031-.086-.273-.197-.507-.3-.616-.149-.148-.385-.242-.77-.242h-.63c-.26
                                      0-.26.016-.26.305v1.364M51.215 33.986c0
                                       .328.015.484.165.515.032.031.024.164-.024.187-.133.023-.338.047-.55.117-.276.086-.954.187-1.395.187-.535
                                        0-.992-.055-1.551-.273-.922-.367-1.552-1.232-1.552-2.355 0-.975.441-1.832
                                         1.386-2.355.536-.288 1.174-.413 1.843-.413.874 0 1.308.187
                                          1.56.187.023.234.031.78.11 1.256-.032.085-.244.093-.3.015-.251-.733-.748-1.1-1.48-1.1-1.252
                                           0-1.859 1.03-1.859 2.262 0 1.115.386 1.778.898 2.121.378.25.788.296 1.08.296.448 0
                                            .543-.086.543-.592v-.492c0-.42-.048-.468-.426-.522l-.346-.047c-.087-.047-.087-.226
                                             0-.273.559.016.945.024 1.33.024.379 0 .765-.008 1.032-.024.063.047.063.226 0
                                              .273l-.18.031c-.237.04-.284.117-.284.538v.437M56.806
                                               30.844c0-.726-.047-.772-.401-.843l-.197-.039c-.063-.046-.071-.218.015-.265a28.095 28.095 0 0 0 2.3
                                                0c.063.047.063.219 0 .265l-.189.032c-.354.062-.402.124-.402.85v1.8c0 .913.103 1.92 1.3 1.92 1.008
                                                 0 1.315-.874 1.315-1.942v-.944c0-.632-.031-1.224-.118-1.427-.07-.164-.244-.21-.457-.25l-.212-.039c-.063-.062-.048-.218
                                                  0-.265.425.016.732.024 1.055.024.291 0 .583-.008.882-.024.047.047.063.203 0
                                                   .265l-.142.032c-.205.047-.354.093-.425.257-.087.203-.118.795-.118 1.427v.873c0 .733-.071 1.404-.504
                                                    1.88-.347.366-.922.561-1.56.561-.598 0-1.134-.094-1.559-.437-.41-.335-.583-.912-.583-1.762v-1.95M67.927 31.974c0
                                                     .273.023.297.488.297.283 0 .449-.07.598-.219.134-.132.284-.382.284-.842 0-.702-.418-1.154-1.032-1.154-.323
                                                      0-.338.023-.338.398v1.52zm-1.08-.99c0-.78-.015-.866-.346-.912l-.213-.031c-.079-.04-.079-.234.016-.274.59-.039
                                                       1.205-.07 1.953-.07.67 0 1.244.102 1.544.281.457.265.693.655.693 1.154 0 .694-.473.99-.835
                                                        1.17-.079.039-.079.086-.04.171.52 1.045.843 1.63 1.221 1.95a.97.97 0 0 0
                                                         .528.218c.07.039.07.156.032.203-.126.054-.378.07-.623.07-.724 0-1.18-.257-1.528-.819a9.7 9.7 0 0
                                                          1-.614-1.208c-.094-.21-.22-.273-.417-.273-.276 0-.291.015-.291.171v.96c0 .717.047.771.401.834l.213.039c.055.039.055.226
                                                           0 .273a28.676 28.676 0 0 0-2.307 0c-.064-.047-.064-.234
                                                            0-.273l.212-.04c.354-.062.402-.116.402-.834v-2.76zM78.18 29.955c-.968
                                                            0-1.488.897-1.488 2.199 0 .951.339 2.48 1.622 2.48 1.032 0 1.433-.999 1.433-2.262
                                                             0-1.333-.59-2.417-1.567-2.417zm2.828 2.316c0 1.7-1.15 2.721-2.844 2.721-1.582
                                                              0-2.732-1.03-2.732-2.659 0-1.544 1.165-2.737 2.811-2.737 1.638 0 2.765 1.092 2.765
                                                               2.675zM87.268 34.992c-.724 0-1.173-.203-1.338-.296-.15-.172-.252-.733-.252-1.264.055-.101.236-.109.3-.023.157.507.684
                                                                1.224 1.353 1.224.583 0 .851-.397.851-.787
                                                                 0-.632-.598-1.006-1.07-1.24-.568-.28-1.19-.749-1.19-1.52-.008-.882.685-1.49
                                                                  1.827-1.49.26 0 .583.031.898.125.102.03.173.046.267.062.064.172.142.593.142
                                                                   1.115-.039.094-.236.102-.307.024-.133-.398-.472-.967-1.04-.967-.52 0-.803.335-.803.725
                                                                    0 .359.323.686.717.889l.52.273c.488.257 1.11.71 1.11 1.567 0 .96-.795 1.583-1.985 1.583"
              />{' '}
            </g>{' '}
          </svg>
        </div>
      </div>
    </div>
  );
};
