import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { withStyles,makeStyles } from '@material-ui/core/styles';
import { convertMb } from '../../Utils/Tools';
import PreviewFile from './PreviewFile';
import { showLoading } from "../../hooks/showLoading";
import { FORMULARIO_PRINCIPAL } from '../../Utils/MensajesSnack';
import { deleteFile, uploadFile } from "../../Utils/FirebaseMethods";
import FileAddIcon from "@material-ui/icons/NoteAddOutlined";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  titleBeneficiarios: {
    flexDirection: "row",
    alignContent: "center",
    alignItems: "flex-end",
    display: "flex",
    justifyContent: "space-between",
  },
  input: {
    display: "none",
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: "#002E71",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function FilesTable({idPart, noSolicitud, documents, pictures, state, setState, setSnack}) {
  const classes = useStyles();
  async function onDeleteFile(file, type, e) {
    var date = new Date();
    e.preventDefault();
    try {
      showLoading(true);

      var data = await deleteFile(idPart, file, type, noSolicitud);
      if (data.type === "doc") {
        showLoading(false);
        setState({ ...state, documents: data.data, lastChange: date });
      } else if (data.type === "img") {
        showLoading(false);
        setState({ ...state, pictures: data.data, lastChange: date });
      }
    } catch (e) {
      showLoading(false);
      setSnack(FORMULARIO_PRINCIPAL.error_eliminar_archivo);
    }
  }
  
  async function onSelectFile() {
    var date = new Date();
    try {
      showLoading(true);
      var name = document.getElementById("fileUploadInput");
      if (name != null) {
        var data = await uploadFile(idPart, name.files.item(0), noSolicitud);
        if (data.type === "doc") {
          showLoading(false);
          setState({...state,documents: data.data,lastChange: date});
        } else if (data.type === "img") {
          showLoading(false);
          setState({...state,pictures: data.data,lastChange: date});
        }
      }
    } catch (e) {
      showLoading(false);
      setSnack(FORMULARIO_PRINCIPAL.error_archivo_adjunto);
    }
  }
  return (
    <div>
      <div className={classes.titleBeneficiarios}>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            alignContent: "center",
            alignItems: "flex-end",
          }}
        >
          {/* <PersonIcon fontSize="large" /> */}
          <Typography variant="h6">Archivos adjuntos</Typography>
        </div>
        <div>
          <input
            accept="image/*, application/pdf"
            className={classes.input}
            id="fileUploadInput"
            type="file"
            onChange={onSelectFile}
            onClick={(event) => (event.target.value = null)}
          />
          <label htmlFor="fileUploadInput">
            <Button
              variant="contained"
              color="primary"
              component="span"
              size="medium"
              startIcon={<FileAddIcon />}
            >
              Adjuntar archivo
            </Button>
          </label>
        </div>
      </div>

    {Object.keys(documents).length === 0 &&
    Object.keys(pictures).length === 0 ?
    <div/>
    :
    <div style={{ marginTop: "2%" }}>
    <TableContainer
      component={Paper}
      style={{
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#B0BEC5",
      }}
    >
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">Nombre</StyledTableCell>
            <StyledTableCell align="left">Tamaño</StyledTableCell>
            <StyledTableCell align="right">Visualizar</StyledTableCell>
            <StyledTableCell align="right">Eliminar</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(documents).map((key) => (
            <StyledTableRow key={documents[key].name}>
              <TableCell component="th" scope="row">
                {documents[key].name}
              </TableCell>
              <TableCell component="th" scope="row">
                {convertMb(documents[key].size)}
              </TableCell>
              <TableCell scope="row" align="right">
                <PreviewFile
                  title={documents[key].name}
                  type="doc"
                  src={documents[key].url}
                ></PreviewFile>
              </TableCell>
              <TableCell align="right">
                <IconButton
                  color="primary"
                  onClick={(e) =>
                    onDeleteFile(documents[key], "doc", e)
                  }
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </StyledTableRow>
          ))}
          {Object.keys(pictures).map((key) => (
            <StyledTableRow key={pictures[key].name}>
              <TableCell component="th" scope="row">
                {pictures[key].name}
              </TableCell>
              <TableCell component="th" scope="row">
                {convertMb(pictures[key].size)}
              </TableCell>

              <TableCell scope="row" align="right">
                <PreviewFile
                  title={pictures[key].name}
                  type="img"
                  src={pictures[key].url} />
              </TableCell>
              <TableCell scope="row" align="right">
                <IconButton color="primary"
                  onClick={(e) => onDeleteFile(pictures[key], "img", e)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </div>
  }
  </div>
  );
}