import React from "react";
import { Redirect, Route } from "react-router-dom";
import Context from "./Context";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Context.Consumer>
          {({ isAuth }) =>
            isAuth ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: "/iniciar-sesion",
                  state: { from: props.location },
                }}
              />
            )
          }
        </Context.Consumer>
      )}
    />
  );
};
