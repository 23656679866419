import {
  InputAdornment,
  TextField,
  IconButton,
  Grid,
  Button,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import SnackBarAlert from '../components/Generico/SnackBarAlert';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import AccountCircle from '@material-ui/icons/SupervisorAccount';
import { initSessionService, createLtpaToken } from '../Utils/Service';
import { CardImage } from '../components/Generico';
import { showLoading } from '../hooks/showLoading';
import imageGnp from '../assets/gnp-logo.svg';
import { Context } from '../Context';
import React, { useState, useEffect, useContext } from 'react';
const initState = {
  email: '',
  password: '',
  showPassword: false,
  errors: {},
  msjSnakcBar: '',
  openSnackBar: false,
};
const home = {
  pathname: '/',
  search: '',
  state: {},
};
const visor_documentos = {
  pathname: '/visor-documentos',
  search: '',
  state: {},
};

const usuarioEnSesion = (history) => {
  history.push(home);
};

const isDesabled = (email, password) => {
  return email === '' || password === '';
};
const passVisible = (showPassword) => {
  return showPassword ? <VisibilityOff /> : <Visibility />;
};

const typePass = (showPassword) => {
  let type = 'password';
  if (showPassword) {
    type = 'text';
  }

  return type;
};

const showLogin = (isAuth, LTPA) => {
  return isAuth || LTPA;
};

const verificaPerfil = (history) => {
  if (window.sessionStorage.getItem('tipoUsuario') === 'intermediarios') {
    history.push(home);
  } else {
    showLoading(false);
    history.push(visor_documentos);
  }
};
export const Login = (props) => {
  const { isAuth, LTPA, activateAuth } = useContext(Context);
  const [state, setState] = useState(initState);

  useEffect(() => {
    if (isAuth) {
      usuarioEnSesion(props.history);
    } else {
      sesionPortal();
    }
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setState({
      ...state,
      showPassword: !state.showPassword,
    });
  };

  function closeSnack() {
    setState({
      ...state,
      msjSnakcBar: '',
      openSnackBar: false,
    });
  }

  const setSesion = (auth) => {
    sessionStorage.setItem('auth', JSON.stringify(auth));
    activateAuth();
    verificaPerfil(props.history);
  };

  const handleClickIniciaSesion = async () => {
    showLoading(true);
    const auth = await initSessionService(state.email, state.password);
    if (auth !== null) {
      setSesion(auth);
    }
    setState({
      ...state,
      msjSnakcBar: 'Usuario y/o contraseña incorrectos',
      openSnackBar: true,
    });
  };

  const setPortal = (cookie) => {
    const list = cookie.split(' ');
    let auth = '';
    list.map(async (k, v) => {
      if (k.includes('LtpaToken2')) {
        auth = await createLtpaToken(k);
        if (auth !== null) {
          sessionStorage.setItem('auth', JSON.stringify(auth));
          activateAuth();
          verificaPerfil(props.history);
        }
      }
    });
  };

  const sesionPortal = async () => {
    showLoading(true);
    const cookie = document.cookie;
    if (cookie !== '') {
      setPortal(cookie);
    }
    showLoading(false);
  };
  const { email, password, showPassword } = state;

  return showLogin(isAuth, LTPA) ? (
    <div></div>
  ) : (
    <React.Fragment>
      <Grid container justify="center" alignItems="center" id="Login">
        <Grid item xs={8} sm={8} md={8}>
          <CardImage
            className="img__login"
            imgURL={imageGnp}
            title="Ingresa tus Datos"
          />
          <TextField
            autoComplete="false"
            variant="outlined"
            type="email"
            label="Usuario"
            margin="normal"
            name="email"
            value={email}
            onChange={handleChange}
            //error={email && email.error}
            //helperText={email && email.message}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" aria-label="Ingresar Usuario">
                    <AccountCircle color="secondary" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            autoComplete="false"
            variant="outlined"
            margin="normal"
            type={typePass(showPassword)}
            label="Contraseña"
            fullWidth
            name="password"
            value={password}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {passVisible(showPassword)}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            margin="normal"
            size="medium"
            color="primary"
            fullWidth
            onClick={(e) => {
              handleClickIniciaSesion();
            }}
            disabled={isDesabled(email, password)}
          >
            Ingresar
          </Button>
        </Grid>
        <SnackBarAlert
          severity="error"
          isOpen={state.openSnackBar}
          message={state.msjSnakcBar}
          closeSnack={closeSnack}
        />
      </Grid>
      ;
    </React.Fragment>
  );
};
