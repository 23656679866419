import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SnackBarAlert from '../components/Generico/SnackBarAlert';
import AlertDialog from '../components/Generico/AlertDialog';
import PreviewFirmantes from '../components/Generico/PreviewFirmantes';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Button from '@material-ui/core/Button';

import {
  newSolicitud,
  deleteSolicitud,
  updateEstatusSolicitud,
  saveFilesFad,
} from '../Utils/FirebaseMethods';

import { showLoading } from '../hooks/showLoading';
import { Context } from '../Context';
import Firebase from '../Utils/Firebase';
import { obtenerEstatusFAD, obtenerArchivos } from '../Utils/Service';
import { HOME } from '../Utils/MensajesSnack';
import HomeTable from '../components/Generico/HomeTable';

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'column',
  },
  card: {
    marginLeft: '2%',
    marginRight: '2%',
  },
  cardContent: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
}));


const initState = {
  solicitudesFirebase: [],
  selectedSolicitud: '',
  openAlerDialog: false,
  titleAlerDialog: '',
  messageAlerDialog: '',
  onSubmitLabelAlerDialog: '',
  onSubmitAlerDialog: null,
  openPreviewFirmantes: false,
  titlePreviewFirmantes: '',
  dataPreviewFirmantes: [],
  dataSolicitudFirmantes: {},
};

function useAsyncState(initialValue) {
  const [value, setValue] = useState(initialValue);
  const setter = (x) =>
    new Promise((resolve) => {
      setValue(x);
      resolve(x);
    });
  return [value, setter];
}

const stateSnack = {
  msjSnackbar: '',
  severitySnackBar: 'success',
  openSnackBar: false,
};
export const Home = (props) => {
  const { sesionData } = useContext(Context);
  const classes = useStyles();
  const [state, setState] = useState(initState);
  const [snackbar, setSnack] = useAsyncState(stateSnack);
  const { openSnackBar, msjSnackbar, severitySnackBar } = snackbar;
  const { solicitudesFirebase} = state;
  
  useEffect(() => {
    showLoading(true);
    Firebase.firmaDigitalVidaMas()
      .doc('solicitudes_generadas')
      .collection(sesionData.idPart)
      .orderBy('date', 'desc')
      .onSnapshot(
        (docSnapshot) => {
          var lista = [];
          docSnapshot.docs.forEach((doc) => {
            if (doc.data().estatus_solicitud !== 'Eliminada') {
              lista.push(doc.data());
              if (doc.data().estatus_solicitud === 'Firmada') {
                saveFilesAuto(doc.data());
              }
            }
            setState({
              ...state,
              solicitudesFirebase: lista,
            });
          });
          showLoading(false);
        },
        (err) => {
          console.log(`Encountered error: ${err}`);
          showLoading(false);
        }
      );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function createSolicitudFirebase() {
    try {
      var noSolicitud = await newSolicitud(sesionData.idPart);
      props.history.push({pathname: '/nueva-solicitud',state: { solicitud: noSolicitud }});
    } catch (e) {
      console.log(e);
    }
  }
  function continueToSolicitud(noSolicitud, e) {
    e.preventDefault();
    props.history.push({pathname: '/nueva-solicitud',state: { solicitud: noSolicitud }});
  }
  async function getStatusFad(solicitud, e) {
    e.preventDefault();
    showLoading(true);
    var consulta = await obtenerEstatusFAD(solicitud.firma_data.requisitionId);
    showLoading(false);
    if (!consulta.data.error) {
      switch (consulta.data.data.status) {
        case 'PENDING':
          setState({
            ...state,
            openPreviewFirmantes: true,
            titlePreviewFirmantes: solicitud.no_solicitud,
            dataPreviewFirmantes: consulta.data.data.signers,
            dataSolicitudFirmantes: solicitud,
          });
          break;
        case 'SIGNED':
          setSnack(HOME.firma_completada);
          setTimeout(function () {
            checkFilesFad(solicitud).then((okay) => {
              updateEstatusSolicitud(
                sesionData.idPart,
                solicitud.no_solicitud,
                'Firmada');});
          }, 3000);
          break;
        default:
          setSnack(HOME.estado_desconocido);
          break;
      }
    } else {
      setSnack(HOME.estado_no_disponible);
    }
  }
  async function checkFilesFad(solicitud) {
    showLoading(true);
    if (solicitud.archivos.FAD.pdf === null) {
      const files = await obtenerArchivos(solicitud.firma_data.requisitionId);
      if (files !== null) {
        if (!files.data.error) {
          await saveFilesFad(
            files,
            solicitud.no_solicitud,
            solicitud.agente_data.uid
          ).then((promesa) => {
            showLoading(false);
          });
        } else {
          showLoading(false);
          setSnack(HOME.error_archivos);
        }
      } else {
        showLoading(false);
        setSnack(HOME.error_archivos);
      }
    }
  }
  async function saveFilesAuto(solicitud) {
    if (solicitud.archivos.FAD.pdf == null) {
      const files = await obtenerArchivos(solicitud.firma_data.requisitionId);
      if (files !== null) {
        if (!files.data.error) {
          await saveFilesFad(
            files,
            solicitud.no_solicitud,
            solicitud.agente_data.uid
          ).then((promesa) => console.log);
        }
      }
    }
  }
  function showDialogEliminar(solicitud, e) {
    e.preventDefault();
    setState({
      ...state,
      selectedSolicitud: solicitud,
      openAlerDialog: true,
      titleAlerDialog: 'Atención',
      messageAlerDialog: 'Desea eliminar la solicitud ' + solicitud,
      onSubmitLabelAlerDialog: 'Eliminar',
      onSubmitAlerDialog: deleteSolicitudFirebase,
    });
  }
  function closeDialogEliminar() {
    setState({
      ...state,
      selectedSolicitud: '',
      openAlerDialog: false,
      titleAlerDialog: '',
      messageAlerDialog: '',
      onSubmitLabelAlerDialog: '',
      onSubmitAlerDialog: null,
    });
  }
  function closePreviewFirmantes() {
    setState({
      ...state,openPreviewFirmantes: false,titlePreviewFirmantes: '',dataPreviewFirmantes: []});
  }
  function deleteSolicitudFirebase(solicitud) {
    deleteSolicitud(sesionData.idPart, solicitud);
  }
  function closeSnack() {
    setSnack(HOME.init_snack);
  }
  function getActions(solicitud) {
    switch (solicitud.estatus_solicitud) {
      case 'Pendiente':
        return (e) => continueToSolicitud(solicitud.no_solicitud, e);
      case 'En proceso de firma':
        return (e) => getStatusFad(solicitud, e);
      case 'Firmada':
        return (e) => checkFilesFad(solicitud);
      default:
        return (e) => console.log(solicitud.estatus_solicitud);
    }
  }
  return (<div className={classes.container}>
      <Card className={`${classes.card}} container-pages`}>
        <CardContent className={classes.cardContent}>
          <div style={{ paddingBottom: '5%', paddingTop: '2%' }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={createSolicitudFirebase}>
              Nuevo Documento
            </Button>
          </div>
          <HomeTable 
          solicitudesFirebase = {solicitudesFirebase}
          getActions ={getActions}
          showDialogEliminar = {showDialogEliminar}/>
        </CardContent>
      </Card>
      <AlertDialog props={state} onClose={closeDialogEliminar} />
      <PreviewFirmantes state={state} onClose={closePreviewFirmantes} />
      <SnackBarAlert
        severity={severitySnackBar}
        isOpen={openSnackBar}
        message={msjSnackbar}
        closeSnack={closeSnack}/>
    </div>);
};
