import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position:"fixed",
    alignItems:"center",
    alignContent:"center",
    textAlign:"center",
    background: theme.palette.secondary.main,
    minWidth:"100%",
    paddingTop:"10px",
    paddingBottom:"10px",
    bottom:"0",
    zIndex:"1000"
  },
  title: {
    flexGrow: 1,
    color: "#fafafa",
  },
}));



export default function Footer() {

  const classes = useStyles();
  return (
        <div className={classes.root}>
            <Typography variant="body2" className={classes.title}>
                Copyright © 2014 Grupo Nacional Provincial
            </Typography>
        </div>
  );
}
