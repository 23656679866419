export const showLoading = (show) => {
  const spin = document.getElementById('content-loader');
  try {
    if (show) {
      spin.style.display = 'block';
    } else {
      spin.style.display = 'none';
    }
  } catch (error) {
    spin.style.display = 'none';
    console.log(error)
  }
};
