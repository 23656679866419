import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { getTooltip } from '../../Utils/Tools';
import ContinueIcon from '@material-ui/icons/NavigateNext';
import UpdateIcon from '@material-ui/icons/Sync';
import DeleteIcon from '@material-ui/icons/Delete';
import DownloadIcon from '@material-ui/icons/PictureAsPdf';
import IconButton from '@material-ui/core/IconButton';
import PreviewFiles from './PreviewFile';

export default function RowSolicitud({solicitud, onClickPrincipal, onClickSecondary}) {

    const useStyles = makeStyles((theme) => ({
        iconPdf: {
          color: theme.palette.status.terminado,
        },
        iconUpdate: {
          color: theme.palette.status.proceso,
        },
      }));
      const classes = useStyles();
    
    const StyledTableRow = withStyles((theme) => ({
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      }))(TableRow);
    
      function GetActions({solicitud}){
        switch (solicitud.estatus_solicitud) {
          case 'Pendiente':
              return (
                <TableCell align="left">
                <Tooltip title={getTooltip(solicitud.estatus_solicitud)}>
                    <IconButton
                      color="primary"
                      onClick={onClickPrincipal}>
                      <ContinueIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={'Eliminar solicitud ' + solicitud.no_solicitud}>
                    <IconButton
                      color="primary"
                      onClick={onClickSecondary}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              );
          case 'En proceso de firma':
              return (
                <TableCell align="left">
                  <Tooltip title="Verificar estado de firma">
                    <IconButton
                      color="primary"
                      onClick={onClickPrincipal}
                    >
                      <UpdateIcon className={classes.iconUpdate} />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              );
          case 'Firmada':
              return(
                <TableCell align="left">
                  {solicitud.archivos.FAD.pdf !== null ? (
                    <PreviewFiles
                      title={solicitud.no_solicitud}
                      type="doc"
                      src={solicitud.archivos.FAD.pdf}
                      isFromHome={true}
                    />
                  ) : (
                    <Tooltip title="Obtener carta firmada">
                      <IconButton
                        color="primary"
                        onClick={onClickPrincipal}
                      >
                        <DownloadIcon className={classes.iconPdf} />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
              );
          default:
              return <div/>;
        }
      }
  
    return (
        <StyledTableRow key={solicitud.no_solicitud}>
            <TableCell component="th" scope="row">
              {solicitud.no_solicitud}
            </TableCell>
            <TableCell align="left">{solicitud.solicitud_data.ramo}</TableCell>
            <Hidden smDown>
              <TableCell align="left">
                {solicitud.solicitud_data.documento}
              </TableCell>
            </Hidden>
            <Hidden smDown>
              <TableCell align="left">
                {solicitud.firma_data.hasOwnProperty('firmantes')
                  ? solicitud.firma_data.firmantes.asesor.nombre.toUpperCase()
                  : ''}
              </TableCell>
            </Hidden>
            <Hidden smDown>
              <TableCell align="left">
                {new Date(solicitud.date.seconds * 1000).toLocaleDateString(
                  'en-US'
                )}
              </TableCell>
            </Hidden>
            <Hidden smDown>
              <TableCell align="left">{solicitud.estatus_solicitud}</TableCell>
            </Hidden>
            <GetActions solicitud = {solicitud}/>
          </StyledTableRow>
    );
}