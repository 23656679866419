import React, { useState, useEffect } from "react";
import Card from "@material-ui/core/Card";
import { getCollections } from "../Utils/Service";
import Firebase from "../Utils/Firebase";
import { showLoading } from "../hooks/showLoading";
export const Reportes = () => {
  const [statusReporte, setStatus] = useState({
    numColecciones: 0,
    numDocs: 0,
  });

  useEffect(() => {
    actualizarReportes();
  }, []);

  const actualizarReportes = async () => {
    try {
      showLoading(true);
      const { estatus } = await Firebase.deleteEstadisticasFDVM();
      if (estatus === "Terminado") {
        const lista = await getCollections();
        const uids = lista !== undefined ? lista.data : [];
        const {
          numColecciones,
          numDocs,
        } = await Firebase.createEstadisticasFDVM(uids);

        setStatus({ numColecciones, numDocs });
      }

      showLoading(false);
    } catch (error) {
      console.log("Error".error);
    }
  };

  return (
    <Card className="container-report">
      <span className="reporte">
        Total de Nodos: {statusReporte.numColecciones}
      </span>
      <span className="reporte">
        Solicitudes Totales: {statusReporte.numDocs}
      </span>
    </Card>
  );
};
