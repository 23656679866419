export const FIREBASE_CONFIG =  {
  apiKey: "AIzaSyBBjTaWjA5-4ywIZTBUsgsy-5kRcgddigw",
  authDomain: "gnp-solicitudvidamas-uat.firebaseapp.com",
  databaseURL: "https://gnp-solicitudvidamas-uat.firebaseio.com",
  projectId: "gnp-solicitudvidamas-uat",
  storageBucket: "gnp-solicitudvidamas-uat.appspot.com",
  messagingSenderId: "559912227209",
  appId: "1:559912227209:web:63c933695430b8fdc91158"
};

export const SERVICE_LOGIN = "https://cuentas-uat.gnp.com.mx/auth/login";
export const SERVICE_COMPRESOR =
  "https://api-visorfdd-uat.gnp.com.mx/compresion";
export const SERVICE_LOGIN_FAD =
  "https://us-central1-gnp-solicitudvidamas-uat.cloudfunctions.net/loginFAD";
export const SERVICE_FILES_FAD =
  "https://uat.firmaautografa.com/clients/requisitions/documentsByIdRequisition/";
export const SERVICE_REQUISITION_FAD =
  "https://uat.firmaautografa.com/requisitions/createRequisicionB2C";
export const SERVICE_STATUS_FAD =
  "https://uat.firmaautografa.com/requisitions/info/";
export const SERVICE_LTPA_TOKEN =
  "https://us-central1-gnp-solicitudvidamas-uat.cloudfunctions.net/generarTokenLTPA";
export const SERVICE_GET_COLLECTIONS =
  "https://us-central1-gnp-solicitudvidamas-uat.cloudfunctions.net/consultarSolicitudes";
export const SERVICE_CF =
  "https://us-central1-gnp-solicitudvidamas-uat.cloudfunctions.net/";
export const RAMOS = ["AUTOS", "DAÑOS", "GENERALES", "GMM", "PYMES", "VIDA"];
export const URL_BASE_FAD =
  "https://uatwebfad4.firmaautografa.com/main?ticket=";
