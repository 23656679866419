import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

export default function SnackBarAlert({isOpen, message, closeSnack, severity}) {

  const [state] = React.useState({
    vertical: 'top',
    horizontal: 'right',
  });

  const { vertical, horizontal } = state;

  const handleClose = () => {
    closeSnack();
  };

  return (
  <div>
    <Snackbar open={isOpen} autoHideDuration={3000} anchorOrigin={{ vertical, horizontal }} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
    </Snackbar>
  </div>);
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}