import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { themee } from "./theme";
import { App } from "./App";
import Context from "./Context";
import "core-js/stable";
import "regenerator-runtime/runtime";
ReactDOM.render(
  <Context.Provider>
    <ThemeProvider theme={themee}>
      <App />
    </ThemeProvider>
  </Context.Provider>,
  document.getElementById("app")
);
