import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  ul, li, h1, h2, h3, p, button { margin: 0; padding: 0; }
  ul { list-style: none; }
  button { background: transparent; border: 0; outline: 0 }

  body {
    background: #E5E5E5;
    /* min-height: 100vh; */
    height:100%;
    margin: 0 auto;
    /* max-width: 500px; */
    overscroll-behavior: none;
    width: 100%;
  }

  #app {
    box-shadow: 0 0 10px rgba(0, 0, 0, .05);
    overflow-x: hidden;
    /* min-height: 100vh; */
    padding-bottom: 10px;
  }

  .container {
    background: "#EEEEEE";
    flex-direction: "column";
  }
  .card{
    margin-left: "2%";
    margin-right: "2%";
  }
  .container-pages{
    height: 100%;
    margin:30px 0;
  }
  #customized-dialog-title{
    background:#002E71;
    color:#fff;
  }

  .img__login{
    width: 100%;
    height: 185px;
  }
/**
* CSS Tabla
**/

tbody tr th, tbody tr td{
  padding:0 16px !important;
}

tbody tr:nth-of-type(2n) {
  background: rgba(196, 196, 196, 0.44) !important;
}

tbody tr:nth-of-type(2n + 1) {
  background: rgba(0, 46, 113, 0.16) !important;
}

th>span{
  color: #1A237E !important;
  background-color: #fff;
}

/**
* Mensage send FDD
**/
#message{
  width: 80%;
  margin: 15px auto;
}
.message-success{
  font-size:14px;
  color:#419a5d;
}
.message-warning{
  font-size:14px;
  color:#ffd500;
}
.message-error{
  font-size:14px;
  color:#ff4949;
}

.container-report {
    width: 300px;
    margin: 50px auto;
}
.reporte {
    font-size: 26px;
    text-align: center;
    color: #7db2ff;
    display: inherit;
    padding: 10px;
}
/**
* New Loading
***/
#content-loader {
  background: rgba(255, 255, 255, 0.611764705882353);
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1500;
}
.loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: hsla(0, 0%, 100%, 0.35);
  z-index: 9999;
}
.gnp-loading {
  background: #f9fafc;
  border-radius: 50%;
}
.gnp-loading,
.gnp-loading .logo {
  width: 100px;
  height: 100px;
}
.gnp-loading,
.gnp-loading .logo svg {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
.gnp-loading .logo {
  position: absolute;
  border-radius: 50%;
  background-color: transparent;
}
.gnp-loading .logo svg {
  width: 70px;
  height: 50px;
}
.gnp-loading .circle-orange {
  border-left: 4px solid #ff6f20;
  background-color: transparent;
  animation: rotate 1200ms linear 0s infinite;
}
.gnp-loading .circle-blue {
  border-left: 4px solid #183d7b;
  background-color: transparent;
  animation: rotate 800ms linear 0s infinite;
}
.gnp-loading .circle-blue,
.gnp-loading .circle-orange {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
}
@keyframes rotate {
  0% {
    transform: rotate(0) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(1.2);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}
#cargandoie > div {
  margin-top: 80px;
  text-align: center;
}
#cargandoie p {
  text-align: center;
  margin: 50px;
}

svg {
  height: 100px;
  display: flex;
  margin: auto;
  z-index: 1000;
  position: relative;
}
.MuiSvgIcon-root{
  margin:0;
}
.logo1 {
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 9999;
  position: relative;
}
.opacity {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 998;
  background-color: #000;
  opacity: 0.8;
}

.st0 {
  fill: #f68938;
}

.gnp-circle {
  animation: animationFrames linear 0.9s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
  animation-fill-mode: forwards; /*when the spec is finished*/
  -webkit-animation: animationFrames linear 0.9s;
  -webkit-animation-iteration-count: infinite;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-fill-mode: forwards; /*Chrome 16+, Safari 4+*/
  -moz-animation: animationFrames linear 0.9s;
  -moz-animation-iteration-count: infinite;
  -moz-transform-origin: 50% 50%;
  -moz-animation-fill-mode: forwards; /*FF 5+*/
  -o-animation: animationFrames linear 0.9s;
  -o-animation-iteration-count: infinite;
  -o-transform-origin: 50% 50%;
  -o-animation-fill-mode: forwards; /*Not implemented yet*/
  -ms-animation: animationFrames linear 0.9s;
  -ms-animation-iteration-count: infinite;
  -ms-transform-origin: 50% 50%;
  -ms-animation-fill-mode: forwards; /*IE 10+*/
}

@keyframes animationFrames {
  0% {
    transform: rotate(204deg);
  }
  100% {
    transform: rotate(569deg);
  }
}

@-moz-keyframes animationFrames {
  0% {
    -moz-transform: rotate(204deg);
  }
  100% {
    -moz-transform: rotate(569deg);
  }
}

@-webkit-keyframes animationFrames {
  0% {
    -webkit-transform: rotate(204deg);
  }
  100% {
    -webkit-transform: rotate(569deg);
  }
}

@-o-keyframes animationFrames {
  0% {
    -o-transform: rotate(204deg);
  }
  100% {
    -o-transform: rotate(569deg);
  }
}

@-ms-keyframes animationFrames {
  0% {
    -ms-transform: rotate(204deg);
  }
  100% {
    -ms-transform: rotate(569deg);
  }
}

/**
PDF
***/
#pdf{
  min-height:300px;
  min-width:300px;
  width:800px;
  height:500px;
}

/**
 * Media Querys
 **/
@media only screen and (max-width: 600px) {

.makeStyles-areaLogo-2 h6 {
  display: none;
}

.makeStyles-areaDataAgente-4 > .MuiSvgIcon-root {
  display: none;
}

.MuiCardContent-root.makeStyles-cardContent-7 {
    padding-top: 60px;
}

.container-pages {
    margin-top: 50px;
    padding:0 !important;
}
#documento > div, #producto > div {
    width: 100%;
}
}
`;
