import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { CardFolio } from '../components/Generico';
import Firebase from '../Utils/Firebase';
import { uploadFile, updateEstatusSolicitud } from '../Utils/FirebaseMethods';
import { createPdf } from '../Utils/PDF';
import { createPdfGmm } from '../Utils/PDFGMM.js';
import { showLoading } from '../hooks/showLoading';
import { FormInputs } from '../components/FormInputs/Index';
import ConfirmFile from '../components/Generico/ConfirmFile';
import { Context } from '../Context';
import { fadXML } from '../Utils/FAD';
import blobToHash from 'blob-to-hash';
import {
  obtenerCredenciales,
  crearReqFad,
  comprimirPDF,
} from '../Utils/Service';
import { FORMULARIO_FIRMANTES } from '../Utils/MensajesSnack';
import SnackBarAlert from '../components/Generico/SnackBarAlert';
import HomeButton from '../components/Generico/HomeButton';
const home = {
  pathname: '/',
  search: '',
  state: {},
};
const initState = {
  asesor: false,
  contratante: false,
  solicitante: false,
  cys: false,
  firmantes: {
    asesor: {
      id: 'asesor',
      nombre: '',
      correo: '',
      telefono: '',
    },
    contratante: {
      id: 'contratante',
      nombre: '',
      correo: '',
      telefono: '',
    },
    solicitante: {
      id: 'solicitante',
      nombre: '',
      correo: '',
      telefono: '',
    },
  },
};
const stateModal = {
  openModal: false,
  srcModal: '',
  type: '',
  size: 0,
};
const stateSnack = {
  msjSnackbar: '',
  severitySnackBar: 'success',
  openSnackBar: false,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  cardContainer: {
    marginLeft: '2%',
    marginRight: '2%',
    flexdirection: 'column',
  },
  firmantes: {
    margin: '2%',
  },
  areaHomeButton: {
    padding: '2%',
    display: 'flex',
  },
}));

function useAsyncState(initialValue) {
  const [value, setValue] = useState(initialValue);
  const setter = (x) =>
    new Promise((resolve) => {
      setValue(x);
      resolve(x);
    });
  return [value, setter];
}

const formatBytes = (bytes) => {
  var kb = 1024;
  var ndx = Math.floor(Math.log(bytes) / Math.log(kb));
  var fileSizeTypes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PD', 'EB', 'ZB', 'YB'];

  return {
    size: +(bytes / kb / kb).toFixed(2),
    type: fileSizeTypes[ndx],
  };
};

const delay = (time) => {
  return new Promise((resolve, reject) => {
    time
      ? setTimeout(() => {
          resolve(true);
        }, time)
      : reject(new Error(false));
  });
};

const validateForm = () => {
  const values = document.querySelectorAll('input[aria-invalid]');
  const errors = [];
  values.forEach((isValid) => {
    if (isValid.getAttribute('aria-invalid')) {
      errors.push(isValid.getAttribute('aria-invalid'));
    }
  });
  return errors;
};

const validateErrorsForm = (asesor, contratante) => {
  const errors = validateForm();
  const hasError = errors.every((element) => element === 'false');
  if (hasError && asesor && contratante) {
    return true;
  }

  return false;
};

const verificaDuplicidad = (asesor, contratante, solicitante) => {
  if (
    asesor.nombre === contratante.nombre ||
    asesor.nombre === solicitante.nombre ||
    contratante.nombre === solicitante.nombre
  ) {
    return FORMULARIO_FIRMANTES.nombres_duplicados;
  } else if (
    asesor.correo === contratante.correo ||
    asesor.correo === solicitante.correo ||
    contratante.correo === solicitante.correo
  ) {
    return FORMULARIO_FIRMANTES.corroes_duplicados;
  } else if (
    asesor.telefono === contratante.telefono ||
    asesor.telefono === solicitante.telefono ||
    contratante.telefono === solicitante.telefono
  ) {
    return FORMULARIO_FIRMANTES.telefonos_duplicados;
  } else {
    return true;
  }
};

const sendHome = async (history) => {
  const passTime = await delay(3000);
  if (passTime) {
    history.push(home);
  }
};

const solicitudComplete = async (idPart, noSolicitud, data) => {
  try {
    let pdfData;
    if (data.solicitud_data.ramo === 'GMM') {
      pdfData = await createPdfGmm(data);
    } else {
      pdfData = await createPdf(data);
    }

    const archivo = await comprimirPDF(
      pdfData.pdfDataUri.split(',')[1],
      `${noSolicitud}.pdf`
    );

    if (archivo.status === 200 && !archivo.data.error) {
      const buff = Buffer.from(archivo.data.buffer.data);
      const fileSize = formatBytes(archivo.data.size);
      const fileSaved = await uploadFile(idPart, buff, noSolicitud);
      return {
        error: false,
        modal: {
          openModal: true,
          srcModal: fileSaved.url,
          size: fileSize.size,
          type: fileSize.type,
        },
        pdf: {
          buff: archivo.data.buffer,
          numPag: pdfData.numPag,
          size: fileSize.size + fileSize.type,
        },
      };
    } else {
      return { error: true, msg: FORMULARIO_FIRMANTES.comprimir_archivo };
    }
  } catch (error) {
    console.log('Ocurrio un error al generar el PDF', error.message);
    return { error: true, msg: FORMULARIO_FIRMANTES.erro_generar_archivo };
  }
};

const sendFDD = async (solicitudData, pdf) => {
  let respuesta = {};
  try {
    showLoading(true);
    const xml = fadXML(solicitudData, pdf);
    const bynaryFile = Buffer.from(pdf.buff.data);
    const dataUri = `data:application/pdf;base64, ${bynaryFile.toString(
      'base64'
    )}`;
    const myBuffer = await fetch(dataUri).then((res) => res.blob());
    const hash = await blobToHash('sha256', myBuffer);
    const auth = await obtenerCredenciales();
    if (auth.status === 200) {
      const aut = `${auth.data.data.token_type} ${auth.data.data.access_token}`;
      const {
        status,
        data: { data, success },
      } = await crearReqFad(xml, myBuffer, hash, aut);
      if (status === 200) {
        if (success) {
          respuesta = {
            error: false,
            data,
            msg: FORMULARIO_FIRMANTES.solicitud_enviada,
          };
        } else {
          respuesta = {
            error: true,
            data,
            msg: FORMULARIO_FIRMANTES.error_proceso_firma,
          };
        }
      }
    }
    return respuesta;
  } catch (error) {
    console.log('Ocurrio un error al enviar a FAD', error.message);
    return {
      error: true,
      data: {},
      msg: FORMULARIO_FIRMANTES.error_generar_archivo,
    };
  }
};

const resetChecks = (inputValue, name, state) => {
  const { firmantes } = state;
  const firmantes_todos = ['asesor', 'contratante', 'solicitante'];
  if (!inputValue) {
    if (name === 'asesor') {
      state.contratante = false;
      state.solicitante = false;
      state.cys = false;
    } else if (name === 'contratante') {
      firmantes_todos.shift();
      state.solicitante = false;
      state.cys = false;
    }
  }
  if (name === 'solicitante' || name === 'cys') {
    state.solicitante = false;
    firmantes_todos.splice(0, 2);
  }
  if (!inputValue || name === 'cys') {
    firmantes_todos.forEach((value) => {
      firmantes[value].nombre = '';
      firmantes[value].correo = '';
      firmantes[value].telefono = '';
    });
  }
};

const completForm = (state) => {
  const { asesor, contratante, solicitante, firmantes } = state;
  const firmantes_todos = [];
  let errorForm = false;
  if (solicitante) {
    firmantes_todos.push('asesor', 'contratante', 'solicitante');
  } else if (asesor || contratante) {
    firmantes_todos.push('asesor', 'contratante');
  }

  firmantes_todos.forEach(async (value, index) => {
    if (
      firmantes[value].nombre !== '' &&
      firmantes[value].correo !== '' &&
      firmantes[value].telefono !== '' &&
      firmantes_todos.length - 1 === index
    ) {
      errorForm = true;
    }
  });
  return errorForm;
};

export const FormularioFirmantes = (props) => {
  const { sesionData } = useContext(Context);
  const classes = useStyles();
  const [noSolicitud] = useState(props.location.state.noSolicitud);
  const [hasError, setError] = useState(false);
  const [modal, setModal] = useState(stateModal);
  const [snackbar, setSnack] = useAsyncState(stateSnack);
  const [pdf, setPDF] = useState({ buff: '', size: {}, numPag: 0 });
  const [solicitudData, setData] = useState({});
  const [state, setState] = useAsyncState(initState);

  useEffect(() => {
    getDataSolicitud();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const errorForm = validateErrorsForm(state.asesor, state.contratante);
    const esValido = completForm(state);
    const complete = errorForm && esValido;
    setError(complete);
  }, [state]);

  const getDataSolicitud = async () => {
    showLoading(true);
    try {
      const result = await Firebase.getData(sesionData.idPart, noSolicitud);
      if (Object.keys(result.data().firma_data).length > 0) {
        setState(result.data().firma_data);
        setData(result.data());
      }
    } catch (error) {
      setSnack(FORMULARIO_FIRMANTES.obtener_datos_solicitud);
    }
    showLoading(false);
  };

  const updateDataSolicitud = (updateData = state) => {
    Firebase.updateData(sesionData.idPart, updateData, noSolicitud);
  };

  const closeSnack = () => {
    setSnack(FORMULARIO_FIRMANTES.init_snack);
  };

  const handleInputs = (e) => {
    const { firmantes } = state;
    firmantes[e.target.id][e.target.name] = e.target.value;
    setState({
      ...state,
    });
    updateDataSolicitud(state);
  };

  const validaDuplicidad = () => {
    const {
      firmantes: { asesor, contratante, solicitante },
    } = state;

    const tiene_duplicados = verificaDuplicidad(
      asesor,
      contratante,
      solicitante
    );

    if (typeof tiene_duplicados === 'object') {
      setSnack(tiene_duplicados);
    } else {
      handleComplete();
    }
  };
  const handleComplete = async () => {
    showLoading(true);
    const result = await Firebase.getData(sesionData.idPart, noSolicitud);
    setData({ ...result.data() });
    const respuesta = await solicitudComplete(
      sesionData.idPart,
      noSolicitud,
      result.data()
    );
    if (respuesta.error) {
      setSnack(respuesta.msg);
    }

    setModal(respuesta.modal);
    setPDF(respuesta.pdf);
    showLoading(false);
  };
  const handleCancel = () => {
    props.history.push(home);
  };
  const handleSendFDD = async () => {
    showLoading(true);
    const resp = await sendFDD(solicitudData, pdf);

    if (!resp.error) {
      const updateState = await setState({ ...state, ...resp.data });
      updateDataSolicitud(updateState);
      updateEstatusSolicitud(
        sesionData.idPart,
        noSolicitud,
        'En proceso de firma'
      );
      resetChecks(false, 'asesor', state);
      setSnack(resp.msg);
      sendHome(props.history);
    } else {
      setSnack(resp.msg);
    }
    showLoading(false);
  };

  const handleChange = async (event) => {
    const name = event.target.name;
    const inputValue = event.target.checked;
    resetChecks(inputValue, name, state);
    const updateState = await setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
    updateDataSolicitud(updateState);
  };

  const { asesor, contratante, solicitante, cys } = state;
  const error = asesor === false;
  const { openSnackBar, msjSnackbar, severitySnackBar } = snackbar;

  return (
    <React.Fragment>
      <Card className={`${classes.cardContainer} container-pages`}>
        <div className={classes.areaHomeButton}>
          <HomeButton props={props} goHome={false} />
        </div>
        <CardFolio solicitud={noSolicitud} />
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12} sm={12} className={classes.firmantes}>
            <FormLabel component="legend">
              Selecciona y completa los datos de las personas que deben firmar
              el documento.
            </FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox checked={asesor} onChange={handleChange} name="asesor" color="primary"/>
                }
                label="Asesor Comercial / Vendedor*"
              />
              {asesor && (
                <div>
                  <FormInputs inputProps={state.firmantes.asesor} handleInputs={handleInputs}/>
                </div>
              )}
              <FormControlLabel
                control={
                  <Checkbox checked={contratante} onChange={handleChange} name="contratante" disabled={!asesor} color="primary"/>
                }
                label="Contratante (Persona física/moral)*"
              />
              {asesor && contratante && (
                <div>
                  <FormInputs inputProps={state.firmantes.contratante} handleInputs={handleInputs}/>
                  <FormControlLabel
                    control={
                      <Switch checked={cys} onChange={handleChange} name="cys" color="primary"/>
                    }
                    label="Contratante es igual al Solicitante"
                  />
                </div>
              )}
              <FormControlLabel
                control={
                  <Checkbox checked={solicitante} onChange={handleChange} disabled={!contratante || cys} name="solicitante" color="primary"/>
                }
                label="Solicitante"
              />
              {contratante && solicitante && (
                <div>
                  <FormInputs inputProps={state.firmantes.solicitante} handleInputs={handleInputs}/>
                </div>
              )}
            </FormGroup>
            <FormHelperText error={error}>
              La firma del Asesor Comercial es necesaria.
            </FormHelperText>
          </Grid>
        </Grid>
        {hasError && (
          <ConfirmFile
            isOpen={modal.openModal}
            title={'Firma Digital'}
            subtitle={'¡Datos Completos!'}
            titleDialog={'Verificar Datos'}
            src={modal.srcModal}
            size={modal.size}
            type={modal.type}
            handleCancel={handleCancel}
            handleComplete={validaDuplicidad}
            handleSendFDD={handleSendFDD}
          ></ConfirmFile>
        )}
        <SnackBarAlert isOpen={openSnackBar} closeSnack={closeSnack} message={msjSnackbar} severity={severitySnackBar}/>
      </Card>
    </React.Fragment>
  );
};
