import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { useInputValue } from "../../hooks/useInputValue";

export const FormInputs = ({ inputProps, handleInputs }) => {
  const nombre = useInputValue(inputProps.nombre);
  const correo = useInputValue(inputProps.correo);
  const telefono = useInputValue(inputProps.telefono);

  const onChangeNombre = (e) => {
    nombre.onChange(e);
    handleInputs(e);
  };
  const onChangeCorreo = (e) => {
    correo.onChange(e);
    handleInputs(e);
  };
  const onChangeTel = (e) => {
    telefono.onChange(e);
    handleInputs(e);
  };

  return (
    <Grid container spacing={3}>
      {/* <form noValidate autoComplete="off"> */}
      <Grid item xs={12} sm={4}>
        <TextField
          error={!nombre.error}
          fullWidth
          label="Nombre completo"
          helperText={
            !nombre.error
              ? "Ingresa tu nombre y un apellido"
              : "Ingresa tu nombre completo."
          }
          required
          value={nombre.value}
          id={inputProps.id}
          name="nombre"
          onChange={onChangeNombre}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          error={!correo.error}
          fullWidth
          label="Correo"
          helperText={
            !correo.error
              ? "Correo inválido."
              : "Ingresa tu correo: pruebas@gnp.com"
          }
          required
          value={correo.value}
          id={inputProps.id}
          name="correo"
          onChange={onChangeCorreo}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          error={!telefono.error}
          fullWidth
          label="Teléfono"
          helperText={
            !telefono.error
              ? "Teléfono inválido"
              : "Ingresa tu teléfono: 5512345689"
          }
          required
          value={telefono.value}
          id={inputProps.id}
          name="telefono"
          inputProps={{
            maxLength: 10,
          }}
          onChange={onChangeTel}
        />
      </Grid>
      {/* </form> */}
    </Grid>
  );
};
