import React from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(2),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export const SolicitudFdd = ({visible, title, subtitle, handleComplete, handleCancel }) => {
  const handleClick = (e) => {
    if (e.target.innerHTML === "Siguiente") {
      handleComplete();
    } else {
      handleCancel();
    }
  };
  const classes = useStyles();
  return (
    visible ?
    <Grid container direction="row" justify="flex-start" alignItems="center">
      <Grid item xs={12} className={classes.margin}>
        <h3>{title}</h3>
        <p>{subtitle}</p>
      </Grid>
      <Button
        variant="contained"
        size="small"
        color="primary"
        id="siguiente"
        className={classes.margin}
        onClick={handleClick}
      >
        Siguiente
      </Button>
      <Button
        variant="outlined"
        size="small"
        color="primary"
        id="cancelar"
        className={classes.margin}
        onClick={handleClick}
      >
        Cancelar
      </Button>
    </Grid>
    : <div></div>
  );
};
