export const checkisEmpty = (value) => {
    if (value === null || value === "") {
      return true;
    }
    return false;
}

export const convertMb = (size) => {
    return (size / 1048576).toFixed(2) + " MB";
}

export const getErrorTextImporte = (isEmptyValue, importe) => {
    if(isEmptyValue){
        return "Este campo es obligatorio";
    }else if(parseFloat(importe) < 1){
        return "El importe no puede ser 0";
    }
    return "";
}

export const getTooltip = (status) => {
    switch (status) {
        case 'Pendiente':
            return "Retomar solicitud";
        case 'En proceso de firma':
            return "Verificar estado de firma";
        case 'Firmada':
            return "Obtener carta firmada";
        default:
            return "";
    }
}

export const structBeneficiario = (tamanio) => {
    return JSON.parse(
        '{"nombres_' +
          (tamanio + 1) +
          '":"", "primer_apellido_' +
          (tamanio + 1) +
          '" :"", "segundo_apellido_' +
          (tamanio + 1) +
          '":"", "parentesco_' +
          (tamanio + 1) +
          '":"", "porcentaje_' +
          (tamanio + 1) +
          '": ""}'
    );
}

export const reOrderListBeneficiarios = (lista) => {
    var reorderList = [];
    lista.forEach((value, index) => {
        var nombres = "";
        var apePat = "";
        var apeMat = "";
        var parentesco = "";
        var porcentaje = "";
        Object.keys(value).forEach((key) => {
          if (key.includes("nombres_")) {
            nombres = value[key];
          } else if (key.includes("primer_apellido")) {
            apePat = value[key];
          } else if (key.includes("segundo_apellido_")) {
            apeMat = value[key];
          } else if (key.includes("parentesco_")) {
            parentesco = value[key];
          } else if (key.includes("porcentaje_")) {
            porcentaje = value[key];
          }
        });
        var beneficiario = JSON.parse(
          '{"nombres_' +
            (index + 1) +
            '": "' +
            nombres +
            '" , "primer_apellido_' +
            (index + 1) +
            '" :"' +
            apePat +
            '", "segundo_apellido_' +
            (index + 1) +
            '":"' +
            apeMat +
            '", "parentesco_' +
            (index + 1) +
            '":"' +
            parentesco +
            '", "porcentaje_' +
            (index + 1) +
            '": "' +
            porcentaje +
            '"}'
        );
        reorderList.push(beneficiario);
    });
    return reorderList;
}

export const changeValueBene = (beneficiarios, id, value) => {
    var tempBeneficiarios = beneficiarios;
    tempBeneficiarios.forEach((beneficiario, index) => {
        var keys = Object.keys(beneficiario);
        keys.forEach((llave) => {
          if (llave === id) {
            tempBeneficiarios[index][llave] = value;
          }
        });
    });
    return tempBeneficiarios;
}

export const validateBeneficiarios = (beneficiarios) => {
    var icomplete = 0;
    var porcentaje = 0;
    if (beneficiarios.length > 0) {
      beneficiarios.forEach((beneficiario) => {
        Object.keys(beneficiario).forEach((key) => {
          if (key.includes("porcentaje")) {
            porcentaje = porcentaje + parseInt(beneficiario[key]);
          }
          if (checkValuesBeneficiarios(key, beneficiario)) {
            icomplete++;
          }
        });
      });
      if (icomplete > 0 || porcentaje > 100 || porcentaje < 100) {
        return [false, porcentaje];
      }
      return [true, porcentaje];
    }
    return [false, 0];
  }

  function checkValuesBeneficiarios(key, beneficiario){
    if( (checkisEmpty(beneficiario[key]) && !key.includes("segundo")) || 
        (key.includes("porcentaje") && (parseInt(beneficiario[key]) < 1 ||
        parseInt(beneficiario[key]) > 100))){
          return true;

    }
    return false;
  }

  export const validateFormVida = (
    ramoSelected, documento, producto, importe, beneficiariosValidate, documents
  ) => {
    if(!checkisEmpty(ramoSelected) && !checkisEmpty(documento) &&
    !checkisEmpty(producto) && !checkisEmpty(importe) &&
    beneficiariosValidate[0] && parseFloat(importe) > 0 && Object.keys(documents).length > 0){
      return true;
    }
    return false;
  }
