import {orange, red, green, yellow } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

export const themee = createMuiTheme({
  palette: {
    primary: {
      main: orange.A700,
      contrastText: "#fff"
      
    },
    secondary: {
      main: '#002E71',
      light: '#AAC3EE',
      contrastText: "#fff"
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#EEEEEE',
    },
    status:{
      pendiente:orange.A700,
      proceso:yellow[700],
      terminado:green[700],
    }
  },
});
