import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DropDown from './DropDown';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import NumberFormat from 'react-number-format';
import DeleteIcon from '@material-ui/icons/Delete';
import { checkisEmpty } from '../../Utils/Tools';

const campo_obligatorio = 'Este campo es obligatorio';
const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'start',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },

  titleBeneficiario: {
    flexGrow: 1,
    alignItems: 'center',
    padding: '1%',
  },
}));

export default function BeneficiarioItem({
  index,
  quit,
  onChangeData,
  beneficiarioData,
  parentescos,
  porcentajeTotal,
}) {
  const classes = useStyles();
  const NOMBRES = 'nombres_';
  const PRIMER_APE = 'primer_apellido_';
  const PORCENTAJE = 'porcentaje_';

  const eliminarBeneficiario = () => {
    quit(index);
  };

  function getAllValues(e) {
    onChangeData(e.target.id, e.target.value);
  }

  return (
    <div id={index} className={classes.titleBeneficiario}>
      Datos beneficiaro {index + 1}
      <div className={classes.root}>
        <div style={{ marginTop: '20px', minWidth: '200px' }}>
          <TextField
            fullWidth
            required
            error={checkisEmpty(beneficiarioData[NOMBRES + (index + 1)])}
            helperText={
              checkisEmpty(beneficiarioData[NOMBRES + (index + 1)])
                ? campo_obligatorio
                : ''
            }
            id={NOMBRES + (index + 1)}
            label="Nombre(s)"
            //size="small"
            onChange={getAllValues}
            value={beneficiarioData[NOMBRES + (index + 1)]}
          />
        </div>

        <div style={{ marginTop: '20px', minWidth: '200px' }}>
          <TextField
            fullWidth
            required
            error={checkisEmpty(beneficiarioData[PRIMER_APE + (index + 1)])}
            helperText={
              checkisEmpty(beneficiarioData[PRIMER_APE + (index + 1)])
                ? campo_obligatorio
                : ''
            }
            id={PRIMER_APE + (index + 1)}
            label="Primer Apellido"
            size="small"
            onChange={getAllValues}
            value={beneficiarioData[PRIMER_APE + (index + 1)]}
          />
        </div>

        <div style={{ marginTop: '20px', minWidth: '200px' }}>
          <TextField
            fullWidth
            id={'segundo_apellido_' + (index + 1)}
            label="Segundo Apellido"
            size="small"
            onChange={getAllValues}
            value={beneficiarioData['segundo_apellido_' + (index + 1)]}
          />
        </div>

        <div style={{ marginTop: '20px' }}>
          <DropDown
            id={'parentesco_' + (index + 1)}
            label="Parentesco"
            items={parentescos}
            size="small"
            onChange={onChangeData}
            valueDrop={beneficiarioData['parentesco_' + (index + 1)]}
          />
        </div>

        <div style={{ marginTop: '20px', minWidth: '200px' }}>
          <TextField
            fullWidth
            required
            error={verifyError(
              beneficiarioData[PORCENTAJE + (index + 1)],
              porcentajeTotal
            )}
            helperText={getErrorMsg(
              beneficiarioData[PORCENTAJE + (index + 1)],
              porcentajeTotal
            )}
            id={PORCENTAJE + (index + 1)}
            label="Porcentaje"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              inputComponent: NumberFormatCustom,
            }}
            size="small"
            onChange={getAllValues}
            value={beneficiarioData[PORCENTAJE + (index + 1)]}
          />
        </div>

        <div style={{ marginTop: '30px' }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={eliminarBeneficiario}
            size="medium"
            startIcon={<DeleteIcon />}
          >
            Eliminar
          </Button>
        </div>
      </div>
    </div>
  );
}

function verifyError(porcentaje, porcentajeTotal) {
  if (
    checkisEmpty(porcentaje) ||
    parseInt(porcentaje) < 1 ||
    parseInt(porcentaje) > 100 ||
    porcentajeTotal !== 100
  ) {
    return true;
  }
  return false;
}

function getErrorMsg(porcentaje, porcentajeTotal) {
  if (checkisEmpty(porcentaje)) {
    return campo_obligatorio;
  } else if (parseInt(porcentaje) < 1) {
    return 'El porcentaje no puede ser 0%';
  } else if (parseInt(porcentaje) > 100) {
    return 'El porcentaje no puede ser mayor a 100%';
  } else if (porcentajeTotal !== 100) {
    return 'El total de porcentajes debe acomular 100%';
  } else {
    return '';
  }
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            id: props.id,
            value: values.value,
          },
        });
      }}
      isNumericString
      decimalSeparator={false}
    />
  );
}
