import React, { useState, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { checkisEmpty } from "../../Utils/Tools";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 200,
  },
}));

export default function DropDown({ id, label, items, onChange, valueDrop }) {
  const classes = useStyles();
  const [value, setValue] = useState(valueDrop);
  //eslint-disable-next-line
  useEffect(() => {
    setValue(valueDrop);
  });

  const handleChange = (e, valueDrop) => {
    setValue(valueDrop);
    onChange(id, valueDrop);
  };

  function getJsonItems(){
    var list = [];
    items.forEach(val => {
      if(val.data().active){
        list.push(val.data().name)
      }
    });

    return list;
  }

  return (
    <FormControl error={checkisEmpty(value)} className={classes.formControl} size="small">
       <Autocomplete
        onChange={handleChange}
        value={value}
        options={getJsonItems()}
        getOptionLabel={(option) => option}
        noOptionsText ={"Sin opciones"}
        renderInput={(params) => <TextField {...params} label={label}/>}
        disableClearable={true}
        getOptionSelected={(option) => option === value}
      />
      <FormHelperText>{checkisEmpty(value) ? "Este campo es obligatorio" : ""}</FormHelperText>
    </FormControl>
  );
}
