import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import ShowIcon from "@material-ui/icons/Visibility";
import DownloadIcon from "@material-ui/icons/PictureAsPdf";
import {green } from '@material-ui/core/colors';
import Tooltip from "@material-ui/core/Tooltip";

const styles = (theme) => ({
  rootFile: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButtonFile: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitleFile = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.rootFile} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButtonFile}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContentFile = withStyles((theme) => ({
  root: {
    justifyContent: "center",
    display: "flex",
  },
}))(MuiDialogContent);

export default function PreviewFile({ title, type, src, isFromHome }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {isFromHome ? 
      <Tooltip title="Visualizar carta firmada">
        <IconButton color="primary" onClick={handleClickOpen}>
          <DownloadIcon style={{color: green[700]}} />
        </IconButton>
      </Tooltip>
      
       :
      <IconButton color="primary" onClick={handleClickOpen}>
        <ShowIcon />
      </IconButton>
    }
       
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitleFile id="customized-dialog-title" onClose={handleClose}>
          {title}
        </DialogTitleFile>
        <DialogContentFile dividers>
          {type !== "doc" ? (
            <img style={{ width: "55%", height: "auto" }} src={src} alt={title}/>
          ) : (
            <object
              data={src}
              type="application/pdf"
              height="500px"
              width="500px"
            >
              <embed src={src} type="application/pdf" />
            </object>
          )}
        </DialogContentFile>
      </Dialog>
    </div>
  );
}
